export default {
  'users.title': 'User settings',

  'users.tab.name': 'User',
  'roles.tab.name': 'Roles',
  'users.name': 'User name',
  'users.userName': 'Username',
  'users.fullName': 'Full name',
  'users.phoneNumber': 'Phone number',
  'users.action': 'Action',
  'users.update': 'Update user',
  'users.information': 'User information',
  'users.delete.confirm': 'Are you sure you want to delete?',
  'users.password': 'Password',
  'users.modal.form.password.placeholder': 'Please enter a password',
  'users.userType': 'Role',
  'users.status--1': 'Active',
  'users.status--2': 'Stop operation',
  'users.avatarPicture': 'Avatar picture',
  'users.create': 'Add user',
  'users.delete': 'Delete user',
  'user.content.delete': 'Are you sure you want to delete this user?',
  'users.citizenId': 'ID/Passport number',
  'users.email': 'Email',
  'users.birthday': 'Date of birth',
  'users.address': 'Address',
  'users.gender': 'Gender',
  'users.male': 'Male',
  'users.female': 'Female',
  'users.other': 'Other',
  'users.countryName': 'Country name',
  'users.status': 'Activity status',
  'users.role': 'Role',

  'users.updatedAt': 'Last update',
  'users.status.param': '{status,select, 1 {Active} 2 {Stop operation} other {Unknown}}',
};
