import React from 'react';

import { IRouter } from '@routers/interface';
import { PermissionEnum } from '@modules/permissions/entity';

export const routerGiftInfo: IRouter = {
  path: '/gift/:type/info/:id',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'giftInfo.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  permissionCode: [PermissionEnum.OtherGiftsViewDetail, PermissionEnum.RankingGiftsViewDetail],
};
