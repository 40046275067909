import React from 'react';

import { UserOutlined } from '@ant-design/icons';
import { IRouter } from '@routers/interface';
import { PermissionEnum } from '@modules/permissions/entity';

export const routerUsers: IRouter = {
  path: '/settings/users',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'users.title', //translate here for breadcrumb and sidebar
  masterLayout: true,
  icon: <UserOutlined />,
  permissionCode: [PermissionEnum.UsersView, PermissionEnum.RolesView],
  generatePath(listPermissions: string[]) {
    const permissionTabs = [PermissionEnum.UsersView, PermissionEnum.RolesView];
    if (permissionTabs.find(item => listPermissions?.includes(item))) {
      return `/settings/users?tab=${permissionTabs.find(item => listPermissions.includes(item))}`;
    }
    return '/settings/users';
  },
};