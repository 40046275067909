export default {
  'server.notFound': 'Không tồn tại',
  'server.networkError': 'Lỗi mạng',
  'Server.AccountEmail.NotExists': 'Thông tin đăng nhập không đúng',
  'Server.AddBeat.Success': 'Thêm thành công',
  'Server.DeleteBeat.Success': 'Xóa thành công',
  'Server.UpdateBeat.Success': 'Cập nhập thành công',
  'Mes.Users.Login.IncorrectNameOrPassword': 'Sai tên đăng nhập hoặc mật khẩu',
  'Mes.Users.Login.Successfully': 'Đăng nhập thành công',
  'Mes.Classes.Year.MustHave2Characters': 'Năm học phải có 2 kí tự',
  'Mes.Classes.ClassCode.MustHave2Characters': 'Mã lớp học phải có 2 kí tự',
  'Mes.Classes.Add.Successfully': 'Thêm lớp học thành công',
  'Mes.Questions.Update.Successfully': 'Cập nhật câu hỏi thành công',
  'Mes.Questions.Add.Successfully': 'Thêm câu hỏi thành công',
  'Mes.Users.UpdateSuccessfully': 'Cập nhật thông tin tài khoản thành công',
  'Mes.Users.Add.Successfully': 'Thêm tài khoản thành công',
  'Mes.Users.DeleteUsers.Successfully': 'Xóa thành công',
  'Mes.Researchs.Update.Successfully': 'Cập nhật bài nghiên cứu thành công',
  'Mes.Researchs.Add.Successfully': 'Thêm bài nghiên cứu thành công',
  'Mes.Researchs.Delete.Successfully': 'Xóa bài nghiên cứu thành công',
  'server.data.success': 'Thành công',
  Successful: 'Thành công',

  'Mes.User.SendPasswordRecoveryCodeSuccessfully': 'Link khôi phục đã được gửi tới email của bạn',
  'Mes.User.ResetPasswordSuccessfully': 'Đặt lại mật khẩu thành công',
  'Mes.Profile.GetDetailSuccessfully': 'Lấy thông tin tài khoản thành công',
  'Mes.Profile.OldPassword.Wrong': 'Mật khẩu cũ không đúng',
  'Mes.Profile.ChangePasswordSuccessfully': 'Cập nhật mật khẩu thành công',
  'Mes.Role.UpdateSuccessfully': 'Cập nhật vai trò thành công',
  'Mes.Role.CreateSuccessfully': 'Thêm vai trò thành công',
  'Mes.Role.DeleteSuccessfully': 'Xóa vai trò thành công',
  //=======================================================
  'Mes.Role.Create.Successfully': 'Tạo vai trò thành công',
  'Mes.Role.Create.Failed': 'Tạo vai trò thất bại',
  'Mes.Role.Update.Successfully': 'Cập nhật vai trò thành công',
  'Mes.Role.Update.Failed': 'Cập nhật vai trò thất bại',
  'Mes.Role.Search.Successfully': 'Xem danh sách vai trò thành công',
  'Mes.Role.ViewDetail.Successfully': 'Xem thông tin chi tiết thành công',
  'Mes.Role.Delete.Successfully': 'Xóa vai trò thành công',
  'Mes.Role.Delete.Failed': 'Xóa vai trò thất bại',
  'Mes.Role.AlreadyExist.Code': 'Mã vai trò đã tồn tại',
  'Mes.Role.Required.Code': 'Mã vai trò là bắt buộc',
  'Mes.Role.NotWhiteSpace.Code': 'Mã vai trò không có khoảng trắng',
  'Mes.Role.NotSpecialCharacter.Code': 'Mã vai trò không chứa ký tự đặc biệt(trừ dấu . @)',
  'Mes.Role.Required.Name': 'Tên vai trò là bắt buộc',
  'Mes.Role.OverLength.Name': 'Tên vai trò không vượt quá 255 kí tự',
  'Mes.Role.Required.PermissionCodes': 'Mã quyền là bắt buộc',
  'Mes.Role.NotFound': 'Vai trò không tồn tại',
  'Mes.Role.WasUsed': 'Vai trò đã được sử dụng',
  'Mes.User.Create.Successfully': 'Tạo người dùng thành công',
  'Mes.User.Create.Failed': 'Tạo người dùng thất bại',
  'Mes.User.Update.Successfully': 'Cập nhật người dùng thành công',
  'Mes.User.Update.Failed': 'Cập nhật người dùng thất bại',
  'Mes.User.Search.Successfully': 'Xem danh sách người dùng thành công',
  'Mes.User.ViewDetail.Successfully': 'Xem thông tin chi tiết thành công',
  'Mes.User.Delete.Successfully': 'Xóa người dùng thành công',
  'Mes.User.Delete.Failed': 'Xóa người dùng thất bại',
  'Mes.User.Login.Successfully': 'Đăng nhập thành công',
  'Mes.User.Send.Failed': 'Gửi cho người dùng mail thất bại',
  'Mes.User.CheckRecoveryCode.Successfully': 'Kiểm tra mã khôi phục thành công',
  'Mes.User.SendRecoveryCode.Successfully': 'Gửi mã khôi phục thành công',
  'Mes.User.ResetPassword.Successfully': 'Lấy lại mật khẩu thành công',
  'Mes.User.Refresh.Successfully': 'Làm mới thành công',
  'Mes.User.ChangePassword.Successfully': 'Đổi mật khẩu thành công',
  'Mes.User.Profile.Successfully': 'Lấy thông tin cá nhân thành công',
  'Mes.User.Invalid.Username': 'Tên đăng nhập không hợp lệ',
  'Mes.User.AlreadyExist.Username': 'Tên đăng nhập đã tồn tại',
  'Mes.User.Required.Username': 'Tên đăng nhập là bắt buộc',
  'Mes.User.NotWhiteSpace.Username': 'Tên đăng nhập không có khoảng trắng',
  'Mes.User.NotSpecialCharacter.Username': 'Tên đăng nhập không chứa ký tự đặc biệt(trừ dấu . @)',
  'Mes.User.Invalid.Email': 'Email không hợp lệ',
  'Mes.User.AlreadyExist.Email': 'Email đã tồn tại',
  'Mes.User.Required.Email': 'Email là bắt buộc',
  'Mes.User.NotWhiteSpace.Email': 'Email không có khoảng trắng',
  'Mes.User.OverLength.Name': 'Tên người dùng không vượt quá 250 kí tự',
  'Mes.User.Invalid.AvatarPicture': 'Ảnh đại diện không hợp lệ',
  'Mes.User.Invalid.Password': 'Mật khẩu không hợp lệ',
  'Mes.User.Required.Password': 'Mật khẩu là bắt buộc',
  'Mes.User.IsWrong.OldPassword': 'Mật khẩu cũ không đúng',
  'Mes.User.Invalid.OldPassword': 'Mật khẩu cũ không hợp lệ',
  'Mes.User.Invalid.PhoneNumber': 'Số điện thoại không hợp lệ',
  'Mes.User.Required.RoleId': 'Id vai trò là bắt buộc',
  'Mes.User.Status.Required': 'Trạng thái là bắt buộc',
  'Mes.User.Status.Invalid': 'Trạng thái không hợp lệ',
  'Mes.User.Required.RecoveryCode': 'Mã khôi phục là bắt buộc',
  'Mes.User.NotFound.RecoveryCode': 'Mã  khôi  phục  người  dùng  không tồn tại',
  'Mes.User.NotFound': 'Không tìm thấy người dùng',
  'Mes.User.PleaseCheckEmail': 'Hãy kiểm tra lại email của bạn',
  'Mes.User.Blocked': 'Người dùng đã bị khóa',
  'Mes.UserRefreshToken.Required.Token': 'Mã làm mới là bắt buộc',
  'Mes.UserRefreshToken.WasUsed': 'Mã làm mới đã được sử dụng',
  'Mes.UserRefreshToken.Invalid.Token': 'Mã làm mới không hợp lệ',
  'Mes.UserRefreshToken.Expired.Token': 'Mã làm mới đã hết hạn',
  'Mes.Customer.Delete.Failed': 'Xóa khách hàng không thành công',
  'Mes.Customer.Delete.Successfully': 'Xóa khách hàng thành công',
  'Mes.Customer.Distribute.Failed': 'Phân phối khách hàng không thành công',
  'Mes.Customer.Distribute.Successfully': 'Phân phối khách hàng thành công',
  'Mes.Customer.Search.Failed': 'Tìm kiếm khách hàng không thành công',
  'Mes.Customer.Search.Successfully': 'Tìm kiếm khách hàng thành công',
  'Mes.Customer.ViewList.Failed': 'Xem danh sách khách hàng thất bại',
  'Mes.Customer.ViewList.Successfully': 'Xem danh sách khách hàng thành công',
  'Mes.Customer.Equip.Failed': 'Trang bị cho khách hàng không thành công',
  'Mes.Customer.Equip.Successfully': 'Trang bị cho khách hàng thành công',
  'Mes.Customer.Purchase.Failed': 'Khách hàng mua không thành công',
  'Mes.Customer.Purchase.Successfully': 'Khách hàng mua hàng thành công',
  'Mes.Customer.ViewDetail.Failed': 'Xem chi tiết Khách hàng thất bại',
  'Mes.Customer.ViewDetail.Successfully': 'Xem chi tiết Khách hàng thành công',
  'Mes.Customer.Update.Failed': 'Cập nhật khách hàng không thành công',
  'Mes.Customer.Update.Successfully': 'Cập nhật khách hàng thành công',
  'Mes.Customer.Create.Failed': 'Tạo khách hàng không thành công',
  'Mes.Customer.Create.Successfully': 'Tạo khách hàng thành công',
  'Mes.Customer.Expired.OtpCode': 'Mã Otp khách hàng hết hạn',
  'Mes.Customer.AlreadyExist.PhoneNumber': 'Đã tồn tại Số điện thoại của khách hàng',
  'Mes.Customer.AlreadyExist.NickName': 'Tên khách hàng đã tồn tại',
  'Mes.Customer.NotEnoughLength.Id': 'Id khách hàng không đủ độ dài',
  'Mes.Customer.NotEnough.AccumulatedCoins': 'Không đủ tiền tích lũy của khách hàng',
  'Mes.Customer.OverLength.NickName': 'Tên khách hàng quá dài',
  'Mes.Customer.NotSpecialCharacter.NickName':
    'Biệt danh của khách hàng không chứa ký tự đặc biệt(trừ dấu . @)',
  'Mes.Customer.Required.Id': 'Mã định danh khách hàng bắt buộc',
  'Mes.Customer.Required.OtpCode': 'Mã Otp khách hàng bắt buộc',
  'Mes.Customer.Required.Bio': 'Tiểu sử khách hàng bắt buộc',
  'Mes.Customer.Required.Gender': 'Giới tính khách hàng bắt buộc',
  'Mes.Customer.Required.Status': 'Tình trạng khách hàng bắt buộc',
  'Mes.Customer.Required.AccumulatedCoins': 'Xu tích lũy của khách hàng bắt buộc',
  'Mes.Customer.Required.PhoneNumber': 'Số điện thoại khách hàng bắt buộc',
  'Mes.Customer.Required.NickName': 'Biệt danh của khách hàng bắt buộc',
  'Mes.Customer.Invalid.Id': 'Mã định danh khách hàng không hợp lệ',
  'Mes.Customer.Invalid.OtpCode': 'Mã Otp khách hàng không hợp lệ',
  'Mes.Customer.Invalid.Gender': 'Giới tính khách hàng không hợp lệ',
  'Mes.Customer.Invalid.Status': 'Trạng thái khách hàng không hợp lệ',
  'Mes.Customer.Invalid.AccumulatedCoins': 'Xu tích lũy của khách hàng không hợp lệ',
  'Mes.Customer.Invalid.PhoneNumber': 'Số điện thoại khách hàng không hợp lệ',
  'Mes.Customer.Invalid.NickName': 'Biệt danh của khách hàng không hợp lệ',
  'Mes.Customer.NotFound.Id': 'Không tìm thấy Id khách hàng',
  'Mes.Customer.NotFound.OtpCode': 'Không Tìm Thấy Mã Otp Khách Hàng',
  'Mes.Customer.NotFound.PhoneNumber': 'Không tìm thấy số điện thoại của khách hàng',
  'Mes.Customer.NotFound.NickName': 'Không tìm thấy tên khách hàng',
  'Mes.Customer.NotFound': 'Không tìm thấy khách hàng',
  'Mes.Customer.WasUsed.OtpCodeExpired': 'Mã Otp của khách hàng đã sử dụng hết hạn',
  'Mes.Customer.WasUsed.OtpCodeVerified': 'Mã Otp của khách hàng đã được sử dụng đã được xác minh',
  'Mes.Customer.WasUsed.OtpCode': 'Mã Otp của khách hàng đã được sử dụng',
  'Mes.Customer.Blocked': 'Khách hàng bị chặn',
  'Mes.Customer.Required.Code': 'Mã khách hàng bắt buộc',
  'Mes.CustomerRefreshToken.Invalid.Token': 'Mã thông báo làm mới của khách hàng không hợp lệ',
  'Mes.CustomerRefreshToken.Expired.Token': 'Mã thông báo làm mới của khách hàng đã hết hạn',
  'Mes.Customer.Invalid.Method': 'Phương thức gửi OPT của khách hàng không hợp lệ',
  'Mes.Customer.Required.Method': 'Phương thức gửi OPT của khách hàng đã hết hạn',
  'Mes.Customer.Invalid.ReCaptcha': 'ReCaptcha của khách hàng không hợp lệ',
  'Mes.Province.NotFound.Id': 'Không tìm thấy mã định danh Tỉnh/Thành',
  'Mes.District.NotFound.Id': 'Không tìm thấy mã định danh Quận/Huyện',
  'Mes.Commune.NotFound.Id': 'Không tìm thấy mã định danh Xã/Phường',
  'Mes.CollectHistory.Required.ValidQuantity': 'Số lượng chai Aqua là bắt buộc',
  'Mes.CollectHistory.Required.InvalidQuantity': 'Số lượng chai không phải Aqua là bắt buộc',
  'Mes.CollectHistory.Invalid.Quantity': 'Số lượng chai không hợp lệ',
  'Mes.CollectHistory.Required.Time': 'Thời gian là bắt buộc',
  'Mes.CollectHistory.Required.PayloadId': 'PayloadId là bắt buộc',
  'Mes.CollectHistory.Required.SignData': 'Chứ kí là bắt buộc',
  'Mes.CollectHistory.NotFound.DeviceId': 'Không tìm thấy mã định danh của thiết bị',
  'Mes.Customer.SendOtp.Failed': 'Gửi Otp cho khách hàng không thành công',
  'Mes.ItemStorage.NotFound.CustomerId': 'Không tìm thấy vật phẩm của người dùng',
  'Mes.Collection.Delete.Failed': 'Xóa bộ sưu tập không thành công',
  'Mes.Collection.Delete.Successfully': 'Xóa bộ sưu tập thành công',
  'Mes.Collection.Distribute.Failed': 'Phân phối bộ sưu tập không thành công',
  'Mes.Collection.Distribute.Successfully': 'Phân phối bộ sưu tập thành công',
  'Mes.Collection.Search.Failed': 'Bộ sưu tập tìm kiếm không thành công',
  'Mes.Collection.Search.Successfully': 'Tìm kiếm bộ sưu tập thành công',
  'Mes.Collection.ViewList.Failed': 'Xem bộ sưu tập danh sách không thành công',
  'Mes.Collection.ViewList.Successfully': 'Xem bộ sưu tập danh sách thành công',
  'Mes.Collection.Purchase.Failed': 'Bộ sưu tập mua không thành công',
  'Mes.Collection.Purchase.Successfully': 'Mua bộ sưu tập thành công',
  'Mes.Collection.ViewDetail.Failed': 'Xem chi tiết Bộ sưu tập không thành công',
  'Mes.Collection.ViewDetail.Successfully': 'Xem chi tiết Bộ sưu tập thành công',
  'Mes.Collection.Update.Failed': 'Cập nhật bộ sưu tập không thành công',
  'Mes.Collection.Update.Successfully': 'Cập nhật bộ sưu tập thành công',
  'Mes.Collection.Create.Failed': 'Tạo bộ sưu tập không thành công',
  'Mes.Collection.Create.Successfully': 'Tạo bộ sưu tập thành công',
  'Mes.Collection.Expired': 'Bộ sưu tập đã hết hạn',
  'Mes.Collection.Expired.Id': 'Id bộ sưu tập đã hết hạn',
  'Mes.Collection.Expired.Items': 'Đồ sưu tập đã hết hạn',
  'Mes.Collection.Expired.RepeatTimes': 'Bộ sưu tập đã hết hạn Thời gian lặp lại',
  'Mes.Collection.Expired.EndTime': 'Thời gian kết thúc bộ sưu tập đã hết hạn',
  'Mes.Collection.Expired.StartTime': 'Thời gian bắt đầu thu tiền đã hết hạn',
  'Mes.Collection.Expired.Name': 'Tên bộ sưu tập đã hết hạn',
  'Mes.Collection.Expired.Code': 'Mã Bộ sưu tập đã hết hạn',
  'Mes.Collection.AlreadyExist.Id': 'Id bộ sưu tập đã tồn tại',
  'Mes.Collection.AlreadyExist.Items': 'Các mục bộ sưu tập đã tồn tại',
  'Mes.Collection.AlreadyExist.RepeatTimes': 'Đã tồn tại Bộ sưu tập Số lần lặp lại',
  'Mes.Collection.AlreadyExist.EndTime': 'Đã tồn tại Thời gian kết thúc bộ sưu tập',
  'Mes.Collection.AlreadyExist.StartTime': 'Đã tồn tại Thời gian bắt đầu bộ sưu tập',
  'Mes.Collection.AlreadyExist.Name': 'Tên bộ sưu tập đã tồn tại',
  'Mes.Collection.AlreadyExist.Code': 'Mã bộ sưu tập đã tồn tại',
  'Mes.Collection.NotEnoughLength.Id': 'Id bộ sưu tập không đủ độ dài',
  'Mes.Collection.NotEnoughLength.Items': 'Không Đủ Độ Dài Mục Bộ Sưu Tập',
  'Mes.Collection.NotEnoughLength.RepeatTimes': 'Thu thập không đủ độ dài Số lần lặp lại',
  'Mes.Collection.NotEnoughLength.EndTime': 'Không đủ độ dài Thời gian kết thúc bộ sưu tập',
  'Mes.Collection.NotEnoughLength.StartTime': 'Không đủ độ dài Thời gian bắt đầu thu gom',
  'Mes.Collection.NotEnoughLength.Name': 'Tên bộ sưu tập không đủ độ dài',
  'Mes.Collection.NotEnoughLength.Code': 'Mã bộ sưu tập không đủ độ dài',
  'Mes.Collection.NotEnough.Id': 'Id bộ sưu tập không đủ',
  'Mes.Collection.NotEnough.Items': 'Không Đủ Vật Phẩm Bộ Sưu Tập',
  'Mes.Collection.NotEnough.RepeatTimes': 'Bộ sưu tập không đủ số lần lặp lại',
  'Mes.Collection.NotEnough.EndTime': 'Thu Không Đủ Thời Gian Kết Thúc',
  'Mes.Collection.NotEnough.StartTime': 'Thời gian bắt đầu thu thập không đủ',
  'Mes.Collection.NotEnough.Name': 'Tên bộ sưu tập không đủ',
  'Mes.Collection.NotEnough.Code': 'Không đủ mã bộ sưu tập',
  'Mes.Collection.OverLength.Id': 'Id bộ sưu tập quá dài',
  'Mes.Collection.OverLength.Items': 'Vật phẩm trong bộ sưu tập quá dài',
  'Mes.Collection.OverLength.RepeatTimes': 'Bộ sưu tập quá dài Thời gian lặp lại',
  'Mes.Collection.OverLength.EndTime': 'Thời gian kết thúc thu tiền vượt quá thời gian',
  'Mes.Collection.OverLength.StartTime': 'Thời gian bắt đầu thu tiền quá chiều dài',
  'Mes.Collection.OverLength.Name': 'Tên bộ sưu tập quá dài',
  'Mes.Collection.OverLength.Code': 'Mã Bộ sưu tập quá dài',
  'Mes.Collection.NotSpecialCharacter.Id': 'Id bộ sưu tập ký tự không đặc biệt',
  'Mes.Collection.NotSpecialCharacter.Items': 'Vật phẩm sưu tập nhân vật không đặc biệt',
  'Mes.Collection.NotSpecialCharacter.RepeatTimes':
    'Bộ sưu tập ký tự không đặc biệt Số lần lặp lại',
  'Mes.Collection.NotSpecialCharacter.EndTime':
    'Bộ sưu tập nhân vật không đặc biệt Thời gian kết thúc',
  'Mes.Collection.NotSpecialCharacter.StartTime':
    'Bộ sưu tập ký tự không đặc biệt Thời gian bắt đầu',
  'Mes.Collection.NotSpecialCharacter.Name': 'Tên bộ sưu tập ký tự không đặc biệt',
  'Mes.Collection.NotSpecialCharacter.Code': 'Mã bộ sưu tập ký tự không đặc biệt',
  'Mes.Collection.NotWhiteSpace.Id': 'Id bộ sưu tập không phải khoảng trắng',
  'Mes.Collection.NotWhiteSpace.Items': 'Không phải mục bộ sưu tập khoảng trắng',
  'Mes.Collection.NotWhiteSpace.RepeatTimes':
    'Không phải bộ sưu tập khoảng trắng Thời gian lặp lại',
  'Mes.Collection.NotWhiteSpace.EndTime': 'Không phải bộ sưu tập khoảng trắng Thời gian kết thúc',
  'Mes.Collection.NotWhiteSpace.StartTime': 'Không phải khoảng trắng Thời gian bắt đầu thu thập',
  'Mes.Collection.NotWhiteSpace.Name': 'Không phải tên bộ sưu tập khoảng trắng',
  'Mes.Collection.NotWhiteSpace.Code': 'Không phải mã bộ sưu tập khoảng trắng',
  'Mes.Collection.Required.Id': 'Id bộ sưu tập bắt buộc',
  'Mes.Collection.Required.Items': 'Vật phẩm thu thập bắt buộc',
  'Mes.Collection.Required.RepeatTimes': 'Thời gian lặp lại bộ sưu tập bắt buộc',
  'Mes.Collection.Required.EndTime': 'Thời gian kết thúc thu tiền bắt buộc',
  'Mes.Collection.Required.StartTime': 'Thời gian bắt đầu thu thập bắt buộc',
  'Mes.Collection.Required.Name': 'Tên bộ sưu tập bắt buộc',
  'Mes.Collection.Required.Code': 'Mã bộ sưu tập bắt buộc',
  'Mes.Collection.MustBeEmpty.Id': 'Id bộ sưu tập phải trống',
  'Mes.Collection.MustBeEmpty.Items': 'Các mục trong bộ sưu tập phải trống',
  'Mes.Collection.MustBeEmpty.RepeatTimes': 'Bộ sưu tập phải rỗng Số lần lặp lại',
  'Mes.Collection.MustBeEmpty.EndTime': 'Bộ sưu tập phải rỗng Thời gian kết thúc',
  'Mes.Collection.MustBeEmpty.StartTime': 'Thời gian bắt đầu thu thập phải trống',
  'Mes.Collection.MustBeEmpty.Name': 'Phải để trống tên bộ sưu tập',
  'Mes.Collection.MustBeEmpty.Code': 'Mã bộ sưu tập phải trống',
  'Mes.Collection.Invalid.Id': 'Id bộ sưu tập không hợp lệ',
  'Mes.Collection.Invalid.Items': 'Mục Bộ sưu tập không hợp lệ',
  'Mes.Collection.Invalid.RepeatTimes': 'Thời gian lặp lại bộ sưu tập không hợp lệ',
  'Mes.Collection.Invalid.EndTime': 'Thời gian kết thúc bộ sưu tập không hợp lệ',
  'Mes.Collection.Invalid.StartTime': 'Thời gian bắt đầu thu thập không hợp lệ',
  'Mes.Collection.Invalid.Name': 'Tên bộ sưu tập không hợp lệ',
  'Mes.Collection.Invalid.Code': 'Mã Bộ sưu tập không hợp lệ',
  'Mes.Collection.Repeated.Id': 'Id bộ sưu tập lặp lại',
  'Mes.Collection.Repeated.Items': 'Mục Bộ sưu tập lặp đi lặp lại',
  'Mes.Collection.Repeated.RepeatTimes': 'Bộ sưu tập lặp lại Số lần lặp lại',
  'Mes.Collection.Repeated.EndTime': 'Thời gian kết thúc bộ sưu tập lặp lại',
  'Mes.Collection.Repeated.StartTime': 'Thời gian bắt đầu thu thập lặp lại',
  'Mes.Collection.Repeated.Name': 'Tên bộ sưu tập lặp lại',
  'Mes.Collection.Repeated.Code': 'Mã bộ sưu tập lặp lại',
  'Mes.Collection.NotFound.Id': 'Id bộ sưu tập không tìm thấy',
  'Mes.Collection.NotFound.Items': 'Không tìm thấy mục bộ sưu tập',
  'Mes.Collection.NotFound.RepeatTimes': 'Không tìm thấy bộ sưu tập Số lần lặp lại',
  'Mes.Collection.NotFound.EndTime': 'Không tìm thấy bộ sưu tập Thời gian kết thúc',
  'Mes.Collection.NotFound.StartTime': 'Thời gian bắt đầu thu thập không tìm thấy',
  'Mes.Collection.NotFound.Name': 'Không Tìm Thấy Tên Bộ Sưu Tập',
  'Mes.Collection.NotFound.Code': 'Mã bộ sưu tập không tìm thấy',
  'Mes.Collection.NotFound': 'Bộ sưu tập không tìm thấy',
  'Mes.Collection.WasUsed.Id': 'Id bộ sưu tập đã được sử dụng',
  'Mes.Collection.WasUsed.Items': 'Vật phẩm sưu tập đã qua sử dụng',
  'Mes.Collection.WasUsed.RepeatTimes': 'Đã được sử dụng Bộ sưu tập Số lần lặp lại',
  'Mes.Collection.WasUsed.EndTime': 'Đã sử dụng Thời gian Kết thúc Bộ sưu tập',
  'Mes.Collection.WasUsed.StartTime': 'Thời gian bắt đầu thu hồi đã được sử dụng',
  'Mes.Collection.WasUsed.Name': 'Tên bộ sưu tập đã được sử dụng',
  'Mes.Collection.WasUsed.Code': 'Mã bộ sưu tập đã được sử dụng',
  'Mes.Collection.WasUsed': 'Bộ sưu tập đã qua sử dụng',
  'Mes.ItemGroup.Delete.Failed': 'Xóa nhóm không thành công',
  'Mes.ItemGroup.Delete.Successfully': 'Xóa nhóm thành công',
  'Mes.ItemGroup.Distribute.Failed': 'Phân phối nhóm không thành công',
  'Mes.ItemGroup.Distribute.Successfully': 'Phân phối thành công nhóm vật phẩm',
  'Mes.ItemGroup.Search.Failed': 'nhóm tìm kiếm không thành công',
  'Mes.ItemGroup.Search.Successfully': 'Tìm kiếm nhóm thành công',
  'Mes.ItemGroup.ViewList.Failed': 'Xem Danh sách Nhóm Không thành công',
  'Mes.ItemGroup.ViewList.Successfully': 'Xem danh sách nhóm thành công',
  'Mes.ItemGroup.Equip.Failed': 'Nhóm vật phẩm trang bị không thành công',
  'Mes.ItemGroup.Equip.Successfully': 'Nhóm vật phẩm trang bị thành công',
  'Mes.ItemGroup.ViewDetail.Failed': 'Xem chi tiết nhóm Không thành công',
  'Mes.ItemGroup.ViewDetail.Successfully': 'Xem chi tiết Nhóm hàng thành công',
  'Mes.ItemGroup.Update.Failed': 'Cập nhật nhóm mặt hàng không thành công',
  'Mes.ItemGroup.Create.Failed': 'Tạo nhóm mặt hàng không thành công',
  'Mes.ItemGroup.Create.Successfully': 'Tạo thành công nhóm vật phẩm',
  'Mes.ItemGroup.AlreadyExist.Id': 'Id nhóm đã tồn tại',
  'Mes.ItemGroup.AlreadyExist.Items': 'Đã tồn tại Nhóm',
  'Mes.ItemGroup.AlreadyExist.Name': 'Đã tồn tại Tên nhóm mặt hàng',
  'Mes.ItemGroup.AlreadyExist.Code': 'Mã nhóm vật phẩm đã tồn tại',
  'Mes.ItemGroup.NotEnoughLength.Code': 'Không đủ độ dài Mã nhóm mặt hàng',
  'Mes.ItemGroup.NotEnough.Id': 'Id nhóm mặt hàng không đủ',
  'Mes.ItemGroup.NotEnough.Items': 'Không Đủ Mặt Hàng Nhóm Mặt Hàng',
  'Mes.ItemGroup.NotEnough.Name': 'Không Đủ Tên Nhóm Mặt Hàng',
  'Mes.ItemGroup.NotEnough.Code': 'Không Đủ Mã Nhóm Hàng',
  'Mes.ItemGroup.OverLength.Id': 'Id nhóm mặt hàng quá dài',
  'Mes.ItemGroup.OverLength.Items': 'Mục quá dài Nhóm',
  'Mes.ItemGroup.OverLength.Name': 'Tên nhóm hạng mục quá dài',
  'Mes.ItemGroup.OverLength.Code': 'Mã nhóm hạng mục quá dài',
  'Mes.ItemGroup.Required.Id': 'Mã nhóm mặt hàng bắt buộc',
  'Mes.ItemGroup.Required.Items': 'Mục bắt buộc nhóm',
  'Mes.ItemGroup.Required.Name': 'Tên nhóm mặt hàng bắt buộc',
  'Mes.ItemGroup.Required.Code': 'Mã nhóm mặt hàng bắt buộc',
  'Mes.ItemGroup.MustBeEmpty.Id': 'Id nhóm phải trống',
  'Mes.ItemGroup.MustBeEmpty.Items': 'Phải để trống Nhóm',
  'Mes.ItemGroup.MustBeEmpty.Name': 'Phải để trống Tên nhóm',
  'Mes.ItemGroup.MustBeEmpty.Code': 'Phải để trống mã nhóm mặt hàng',
  'Mes.ItemGroup.Invalid.Id': 'Id nhóm mặt hàng không hợp lệ',
  'Mes.ItemGroup.Invalid.Items': 'Nhóm mặt hàng không hợp lệ',
  'Mes.ItemGroup.Invalid.Name': 'Tên nhóm mặt hàng không hợp lệ',
  'Mes.ItemGroup.Invalid.Code': 'Mã nhóm mặt hàng không hợp lệ',
  'Mes.ItemGroup.Repeated.Id': 'Id nhóm lặp lại',
  'Mes.ItemGroup.Repeated.Items': 'Nhóm Lặp Lại',
  'Mes.ItemGroup.Repeated.Name': 'Tên nhóm lặp lại',
  'Mes.ItemGroup.Repeated.Code': 'Mã nhóm mặt hàng lặp lại',
  'Mes.ItemGroup.NotFound.Id': 'Id nhóm không tìm thấy',
  'Mes.ItemGroup.NotFound.Items': 'Không tìm thấy Nhóm',
  'Mes.ItemGroup.NotFound.Name': 'Tên nhóm không tìm thấy',
  'Mes.ItemGroup.NotFound.Code': 'Mã Nhóm Không Tìm Thấy Vật Phẩm',
  'Mes.ItemGroup.NotFound': 'nhóm không tìm thấy',
  'Mes.ItemGroup.WasUsed.Id': 'Id nhóm vật phẩm đã được sử dụng',
  'Mes.ItemGroup.WasUsed.Items': 'Đã sử dụng Vật phẩm Nhóm Vật phẩm',
  'Mes.ItemGroup.WasUsed.Name': 'Tên nhóm vật phẩm đã được sử dụng',
  'Mes.ItemGroup.WasUsed.Code': 'Mã nhóm vật phẩm đã qua sử dụng',
  'Mes.ItemGroup.WasUsed': 'Nhóm vật phẩm đã qua sử dụng',
  'Mes.ItemGroup.Blocked': 'Nhóm mặt hàng bị chặn',
  'Mes.Item.Delete.Failed': 'Xóa vật phẩm không thành công',
  'Mes.Item.Delete.Successfully': 'Xóa vật phẩm thành công',
  'Mes.Item.Distribute.Failed': 'Phân phối vật phẩm không thành công',
  'Mes.Item.Distribute.Successfully': 'Phân phối vật phẩm thành công',
  'Mes.Item.Search.Failed': 'vật phẩm tìm kiếm không thành công',
  'Mes.Item.Search.Successfully': 'Tìm kiếm vật phẩm thành công',
  'Mes.Item.ViewList.Failed': 'Xem vật phẩm danh sách không thành công',
  'Mes.Item.ViewList.Successfully': 'Xem vật phẩm danh sách thành công',
  'Mes.Item.Equip.Failed': 'vật phẩm trang bị không thành công',
  'Mes.Item.Equip.Successfully': 'Trang bị vật phẩm thành công',
  'Mes.Item.Purchase.Failed': 'Mua hàng không thành công',
  'Mes.Item.Purchase.Successfully': 'Mua hàng thành công',
  'Mes.Item.ViewDetail.Failed': 'Xem chi tiết vật phẩm không thành công',
  'Mes.Item.ViewDetail.Successfully': 'Xem chi tiết Hàng thành công',
  'Mes.Item.Update.Failed': 'Cập nhật vật phẩm không thành công',
  'Mes.Item.Update.Successfully': 'Cập nhật vật phẩm thành công',
  'Mes.Item.Create.Failed': 'Tạo vật phẩm không thành công',
  'Mes.Item.Create.Successfully': 'Tạo vật phẩm thành công',
  'Mes.Item.Expired': 'Hàng hết hạn',
  'Mes.Item.Expired.Id': 'Id mặt hàng đã hết hạn',
  'Mes.Item.Expired.Events': 'Sự kiện vật phẩm hết hạn',
  'Mes.Item.Expired.OwnPreferentials': 'Ưu đãi riêng của mặt hàng đã hết hạn',
  'Mes.Item.Expired.Preferentials': 'Ưu đãi mặt hàng đã hết hạn',
  'Mes.Item.Expired.ItemStorages': 'Vật phẩm hết hạn Kho vật phẩm',
  'Mes.Item.Expired.SoldQuantity': 'Hàng hết hạn Số lượng đã bán',
  'Mes.Item.Expired.SortType': 'Mặt hàng hết hạn Loại sắp xếp',
  'Mes.Item.Expired.BusinessStatus': 'Mặt hàng hết hạn Tình trạng kinh doanh',
  'Mes.Item.Expired.GenderType': 'Mặt hàng hết hạn Loại giới tính',
  'Mes.Item.Expired.ItemGroup': 'Nhóm mặt hàng hết hạn',
  'Mes.Item.Expired.ItemGroupId': 'Id nhóm mặt hàng đã hết hạn',
  'Mes.Item.Expired.Collection': 'Bộ sưu tập vật phẩm đã hết hạn',
  'Mes.Item.Expired.CollectionId': 'Id bộ sưu tập vật phẩm đã hết hạn',
  'Mes.Item.Expired.Overlay': 'Lớp phủ mặt hàng đã hết hạn',
  'Mes.Item.Expired.Thumbnail': 'Hình thu nhỏ mặt hàng đã hết hạn',
  'Mes.Item.Expired.High': 'Hàng hết hạn cao',
  'Mes.Item.Expired.Abscissa': 'Item hết hạn',
  'Mes.Item.Expired.Ordinate': 'Mặt hàng hết hạn',
  'Mes.Item.Expired.ConfigurationObj': 'vật phẩm cấu hình hết hạn Obj',
  'Mes.Item.Expired.Configuration': 'Cấu hình mặt hàng hết hạn',
  'Mes.Item.Expired.Type': 'Loại hàng hết hạn',
  'Mes.Item.Expired.Price': 'Giá hàng hết hạn',
  'Mes.Item.Expired.Quantity': 'Số lượng hàng hết hạn',
  'Mes.Item.Expired.Name': 'Tên mặt hàng hết hạn',
  'Mes.Item.Expired.Code': 'Mã hàng hết hạn',
  'Mes.Item.AlreadyExist.Id': 'Id mặt hàng đã tồn tại',
  'Mes.Item.AlreadyExist.Events': 'Sự kiện vật phẩm đã tồn tại',
  'Mes.Item.AlreadyExist.OwnPreferentials': 'Mặt hàng đã tồn tại Ưu đãi riêng',
  'Mes.Item.AlreadyExist.Preferentials': 'Đã tồn tại Ưu đãi mặt hàng',
  'Mes.Item.AlreadyExist.ItemStorages': 'Đã tồn tại Vật phẩm Kho lưu trữ',
  'Mes.Item.AlreadyExist.SoldQuantity': 'Đã tồn tại Hàng đã bán Số lượng',
  'Mes.Item.AlreadyExist.SortType': 'Đã có mặt hàng Loại sắp xếp',
  'Mes.Item.AlreadyExist.BusinessStatus': 'Đã tồn tại Mặt hàng Tình trạng kinh doanh',
  'Mes.Item.AlreadyExist.GenderType': 'Đã tồn tại vật phẩm Giới tính Loại',
  'Mes.Item.AlreadyExist.ItemGroup': 'Đã tồn tại vật phẩm vật phẩm Nhóm',
  'Mes.Item.AlreadyExist.ItemGroupId': 'Đã tồn tại Item Item Group Id',
  'Mes.Item.AlreadyExist.Collection': 'Bộ sưu tập vật phẩm đã tồn tại',
  'Mes.Item.AlreadyExist.CollectionId': 'Id bộ sưu tập vật phẩm đã tồn tại',
  'Mes.Item.AlreadyExist.Overlay': 'Lớp phủ vật phẩm đã tồn tại',
  'Mes.Item.AlreadyExist.Thumbnail': 'Hình thu nhỏ vật phẩm đã tồn tại',
  'Mes.Item.AlreadyExist.High': 'Hàng đã tồn tại Cao',
  'Mes.Item.AlreadyExist.Abscissa': 'Mặt hàng đã tồn tại',
  'Mes.Item.AlreadyExist.Ordinate': 'Mặt hàng đã tồn tại',
  'Mes.Item.AlreadyExist.ConfigurationObj': 'Đã tồn tại vật phẩm cấu hình Obj',
  'Mes.Item.AlreadyExist.Configuration': 'Cấu hình vật phẩm đã tồn tại',
  'Mes.Item.AlreadyExist.Type': 'Đã tồn tại Loại mặt hàng',
  'Mes.Item.AlreadyExist.Price': 'Giá hàng đã tồn tại',
  'Mes.Item.AlreadyExist.Quantity': 'Hàng đã tồn tại Số lượng',
  'Mes.Item.AlreadyExist.Name': 'Tên mặt hàng đã tồn tại',
  'Mes.Item.AlreadyExist.Code': 'Mã vật phẩm đã tồn tại',
  'Mes.Item.NotEnoughLength.Id': 'Id vật phẩm không đủ độ dài',
  'Mes.Item.NotEnoughLength.Events': 'Không Đủ Độ Dài vật phẩm Sự Kiện',
  'Mes.Item.NotEnoughLength.OwnPreferentials': 'Không Đủ Độ Dài vật phẩm Ưu Đãi Riêng',
  'Mes.Item.NotEnoughLength.Preferentials': 'Không đủ chiều dài vật phẩm ưu đãi',
  'Mes.Item.NotEnoughLength.ItemStorages': 'Không đủ độ dài Vật phẩm Kho vật phẩm',
  'Mes.Item.NotEnoughLength.SoldQuantity': 'Không đủ chiều dài Mặt hàng đã bán Số lượng',
  'Mes.Item.NotEnoughLength.SortType': 'Không đủ độ dài Hạng vật phẩm Loại sắp xếp',
  'Mes.Item.NotEnoughLength.BusinessStatus': 'Không đủ độ dài Mặt hàng Tình trạng kinh doanh',
  'Mes.Item.NotEnoughLength.GenderType': 'Không đủ độ dài Hạng vật phẩm Giới tính Loại',
  'Mes.Item.NotEnoughLength.ItemGroup': 'Không Đủ Độ Dài vật phẩm vật phẩm Nhóm',
  'Mes.Item.NotEnoughLength.ItemGroupId': 'Không Đủ Độ Dài vật phẩm vật phẩm Nhóm Id',
  'Mes.Item.NotEnoughLength.Collection': 'Bộ sưu tập vật phẩm không đủ độ dài',
  'Mes.Item.NotEnoughLength.CollectionId': 'Id bộ sưu tập vật phẩm không đủ độ dài',
  'Mes.Item.NotEnoughLength.Overlay': 'Lớp phủ vật phẩm không đủ độ dài',
  'Mes.Item.NotEnoughLength.Thumbnail': 'Hình thu nhỏ vật phẩm không đủ độ dài',
  'Mes.Item.NotEnoughLength.High': 'Không Đủ Chiều Dài vật phẩm Cao',
  'Mes.Item.NotEnoughLength.Abscissa': 'Không đủ chiều dài vật phẩm Abscissa',
  'Mes.Item.NotEnoughLength.Ordinate': 'Không Đủ Chiều Dài Hạng vật phẩm',
  'Mes.Item.NotEnoughLength.ConfigurationObj': 'Không Đủ Độ Dài vật phẩm Cấu Hình Obj',
  'Mes.Item.NotEnoughLength.Configuration': 'Cấu hình vật phẩm không đủ độ dài',
  'Mes.Item.NotEnoughLength.Type': 'Không đủ độ dài Loại sản phẩm',
  'Mes.Item.NotEnoughLength.Price': 'Không Đủ Chiều Dài Giá Sản Phẩm',
  'Mes.Item.NotEnoughLength.Quantity': 'Không Đủ Chiều Dài vật phẩm Số Lượng',
  'Mes.Item.NotEnoughLength.Name': 'Không đủ độ dài Tên vật phẩm',
  'Mes.Item.NotEnoughLength.Code': 'Không Đủ Độ Dài Mã vật phẩm',
  'Mes.Item.NotEnough.Id': 'ID mặt hàng không đủ',
  'Mes.Item.NotEnough.Events': 'Sự kiện không đủ vật phẩm',
  'Mes.Item.NotEnough.OwnPreferentials': 'Không Đủ Sản Phẩm Ưu Đãi Riêng',
  'Mes.Item.NotEnough.Preferentials': 'Không đủ ưu đãi mặt hàng',
  'Mes.Item.NotEnough.ItemStorages': 'Kho lưu trữ vật phẩm không đủ',
  'Mes.Item.NotEnough.SoldQuantity': 'Không Đủ Hàng Đã Bán Số Lượng',
  'Mes.Item.NotEnough.SortType': 'Không Đủ Mặt Hàng Loại Sắp Xếp',
  'Mes.Item.NotEnough.BusinessStatus': 'Không Đủ Mặt Hàng Tình Trạng Kinh Doanh',
  'Mes.Item.NotEnough.GenderType': 'Không đủ mặt hàng Loại giới tính',
  'Mes.Item.NotEnough.ItemGroup': 'Không Đủ Vật Phẩm Nhóm Vật Phẩm',
  'Mes.Item.NotEnough.ItemGroupId': 'Id nhóm mặt hàng không đủ mặt hàng',
  'Mes.Item.NotEnough.Collection': 'Bộ sưu tập vật phẩm không đủ',
  'Mes.Item.NotEnough.CollectionId': 'Id bộ sưu tập vật phẩm không đủ',
  'Mes.Item.NotEnough.Overlay': 'Lớp phủ vật phẩm không đủ',
  'Mes.Item.NotEnough.Thumbnail': 'Hình thu nhỏ của mặt hàng không đủ',
  'Mes.Item.NotEnough.High': 'Không Đủ Hàng Cao',
  'Mes.Item.NotEnough.Abscissa': 'Không Đủ vật phẩm Abscissa',
  'Mes.Item.NotEnough.Ordinate': 'Không đủ hàng',
  'Mes.Item.NotEnough.ConfigurationObj': 'Không Đủ vật phẩm Cấu Hình Obj',
  'Mes.Item.NotEnough.Configuration': 'Không đủ cấu hình vật phẩm',
  'Mes.Item.NotEnough.Type': 'Loại hàng không đủ',
  'Mes.Item.NotEnough.Price': 'Không Đủ Giá Sản Phẩm',
  'Mes.Item.NotEnough.Quantity': 'Số lượng hàng không đủ',
  'Mes.Item.NotEnough.Name': 'Tên mặt hàng không đủ',
  'Mes.Item.NotEnough.Code': 'Không Đủ Mã Hàng',
  'Mes.Item.OverLength.Id': 'Id mặt hàng quá dài',
  'Mes.Item.OverLength.Events': 'Sự kiện vật phẩm quá dài',
  'Mes.Item.OverLength.OwnPreferentials': 'Ưu đãi dành riêng cho mặt hàng quá dài',
  'Mes.Item.OverLength.Preferentials': 'Ưu đãi mặt hàng quá dài',
  'Mes.Item.OverLength.ItemStorages': 'Quá dài Hạng vật phẩm Hạng vật phẩm Lưu trữ',
  'Mes.Item.OverLength.SoldQuantity': 'Quá chiều dài Hàng đã bán Số lượng',
  'Mes.Item.OverLength.SortType': 'Quá dài Loại vật phẩm sắp xếp',
  'Mes.Item.OverLength.BusinessStatus': 'Hạng vật phẩm quá dài Tình trạng kinh doanh',
  'Mes.Item.OverLength.GenderType': 'Hạng vật phẩm quá dài Loại giới tính',
  'Mes.Item.OverLength.ItemGroup': 'Quá dài Hạng vật phẩm Hạng vật phẩm Nhóm',
  'Mes.Item.OverLength.ItemGroupId': 'Id nhóm mặt hàng quá dài',
  'Mes.Item.OverLength.Collection': 'Bộ sưu tập vật phẩm quá dài',
  'Mes.Item.OverLength.CollectionId': 'Id bộ sưu tập vật phẩm quá dài',
  'Mes.Item.OverLength.Overlay': 'Lớp phủ vật phẩm quá dài',
  'Mes.Item.OverLength.Thumbnail': 'Hình thu nhỏ vật phẩm quá dài',
  'Mes.Item.OverLength.High': 'vật phẩm quá dài cao',
  'Mes.Item.OverLength.Abscissa': 'Abscissa vật phẩm quá dài',
  'Mes.Item.OverLength.Ordinate': 'vật phẩm quá dài',
  'Mes.Item.OverLength.ConfigurationObj': 'Over Length vật phẩm cấu hình Obj',
  'Mes.Item.OverLength.Configuration': 'Cấu hình vật phẩm quá dài',
  'Mes.Item.OverLength.Type': 'Quá dài Loại sản phẩm',
  'Mes.Item.OverLength.Price': 'Chiều Dài Giá Sản Phẩm',
  'Mes.Item.OverLength.Quantity': 'Quá Chiều Dài vật phẩm Số Lượng',
  'Mes.Item.OverLength.Name': 'Tên mặt hàng quá dài',
  'Mes.Item.OverLength.Code': 'Quá Chiều Dài vật phẩm Mã',
  'Mes.Item.Required.Id': 'Id mặt hàng bắt buộc',
  'Mes.Item.Required.Events': 'Vật phẩm bắt buộc Sự kiện',
  'Mes.Item.Required.OwnPreferentials': 'Mặt hàng bắt buộc Ưu đãi riêng',
  'Mes.Item.Required.Preferentials': 'Ưu đãi mặt hàng bắt buộc',
  'Mes.Item.Required.ItemStorages': 'Vật phẩm bắt buộc Kho vật phẩm',
  'Mes.Item.Required.SoldQuantity': 'Mặt hàng yêu cầu Số lượng đã bán',
  'Mes.Item.Required.SortType': 'vật phẩm bắt buộc Loại sắp xếp',
  'Mes.Item.Required.BusinessStatus': 'Mặt hàng bắt buộc Tình trạng kinh doanh',
  'Mes.Item.Required.GenderType': 'vật phẩm bắt buộc Loại giới tính',
  'Mes.Item.Required.ItemGroup': 'vật phẩm yêu cầu Nhóm vật phẩm',
  'Mes.Item.Required.ItemGroupId': 'Mặt hàng bắt buộc Id nhóm mặt hàng',
  'Mes.Item.Required.Collection': 'Bộ sưu tập vật phẩm bắt buộc',
  'Mes.Item.Required.CollectionId': 'Id bộ sưu tập mặt hàng bắt buộc',
  'Mes.Item.Required.Overlay': 'Lớp phủ vật phẩm bắt buộc',
  'Mes.Item.Required.Thumbnail': 'Hình thu nhỏ vật phẩm bắt buộc',
  'Mes.Item.Required.High': 'vật phẩm yêu cầu cao',
  'Mes.Item.Required.Abscissa': 'vật phẩm bắt buộc Abscissa',
  'Mes.Item.Required.Ordinate': 'vật phẩm bắt buộc',
  'Mes.Item.Required.ConfigurationObj': 'vật phẩm bắt buộc Cấu hình Obj',
  'Mes.Item.Required.Configuration': 'Cấu hình vật phẩm bắt buộc',
  'Mes.Item.Required.Type': 'Loại vật phẩm bắt buộc',
  'Mes.Item.Required.Price': 'Giá vật phẩm yêu cầu',
  'Mes.Item.Required.Quantity': 'Số lượng mặt hàng bắt buộc',
  'Mes.Item.Required.Name': 'Tên vật phẩm bắt buộc',
  'Mes.Item.Required.Code': 'Mã hàng bắt buộc',
  'Mes.Item.MustBeEmpty.Id': 'Id mặt hàng phải trống',
  'Mes.Item.MustBeEmpty.Events': 'Sự kiện phải là vật phẩm rỗng',
  'Mes.Item.MustBeEmpty.OwnPreferentials': 'Phải để trống vật phẩm ưu đãi riêng',
  'Mes.Item.MustBeEmpty.Preferentials': 'Phải để trống',
  'Mes.Item.MustBeEmpty.ItemStorages': 'Kho lưu trữ vật phẩm phải trống',
  'Mes.Item.MustBeEmpty.SoldQuantity': 'Phải là hàng rỗng Số lượng đã bán',
  'Mes.Item.MustBeEmpty.SortType': 'Phải để trống Loại sắp xếp',
  'Mes.Item.MustBeEmpty.BusinessStatus': 'Phải là mặt hàng trống Tình trạng kinh doanh',
  'Mes.Item.MustBeEmpty.GenderType': 'Phải để trống vật phẩm Loại giới tính',
  'Mes.Item.MustBeEmpty.ItemGroup': 'Phải để trống vật phẩm vật phẩm Nhóm',
  'Mes.Item.MustBeEmpty.ItemGroupId': 'Phải để trống Id nhóm mặt hàng',
  'Mes.Item.MustBeEmpty.Collection': 'Bộ sưu tập vật phẩm phải trống',
  'Mes.Item.MustBeEmpty.CollectionId': 'Phải để trống Id bộ sưu tập vật phẩm',
  'Mes.Item.MustBeEmpty.Overlay': 'Lớp phủ vật phẩm phải trống',
  'Mes.Item.MustBeEmpty.Thumbnail': 'Hình thu nhỏ của vật phẩm phải trống',
  'Mes.Item.MustBeEmpty.High': 'Phải là vật phẩm trống cao',
  'Mes.Item.MustBeEmpty.Abscissa': 'Phải để trống vật phẩm Abscissa',
  'Mes.Item.MustBeEmpty.Ordinate': 'Phải là vật phẩm trống',
  'Mes.Item.MustBeEmpty.ConfigurationObj': 'Phải là vật phẩm cấu hình trống Obj',
  'Mes.Item.MustBeEmpty.Configuration': 'Phải là vật phẩm cấu hình trống',
  'Mes.Item.MustBeEmpty.Type': 'Phải là loại vật phẩm trống',
  'Mes.Item.MustBeEmpty.Price': 'Giá vật phẩm phải trống',
  'Mes.Item.MustBeEmpty.Quantity': 'Phải để trống Số lượng mặt hàng',
  'Mes.Item.MustBeEmpty.Name': 'Phải để trống Tên vật phẩm',
  'Mes.Item.MustBeEmpty.Code': 'Phải để trống Mã hàng',
  'Mes.Item.Invalid.Id': 'Id mặt hàng không hợp lệ',
  'Mes.Item.Invalid.Events': 'Sự kiện mặt hàng không hợp lệ',
  'Mes.Item.Invalid.OwnPreferentials': 'Ưu đãi riêng của mặt hàng không hợp lệ',
  'Mes.Item.Invalid.Preferentials': 'Ưu đãi mặt hàng không hợp lệ',
  'Mes.Item.Invalid.ItemStorages': 'vật phẩm lưu trữ vật phẩm không hợp lệ',
  'Mes.Item.Invalid.SoldQuantity': 'Mặt hàng không hợp lệ Số lượng đã bán',
  'Mes.Item.Invalid.SortType': 'Loại sắp xếp mặt hàng không hợp lệ',
  'Mes.Item.Invalid.BusinessStatus': 'Tình trạng kinh doanh mặt hàng không hợp lệ',
  'Mes.Item.Invalid.GenderType': 'vật phẩm không hợp lệ Loại giới tính',
  'Mes.Item.Invalid.ItemGroup': 'Nhóm mặt hàng không hợp lệ',
  'Mes.Item.Invalid.ItemGroupId': 'Id nhóm mặt hàng không hợp lệ',
  'Mes.Item.Invalid.Collection': 'Bộ sưu tập vật phẩm không hợp lệ',
  'Mes.Item.Invalid.CollectionId': 'Id bộ sưu tập vật phẩm không hợp lệ',
  'Mes.Item.Invalid.Overlay': 'Lớp phủ vật phẩm không hợp lệ',
  'Mes.Item.Invalid.Thumbnail': 'Hình thu nhỏ vật phẩm không hợp lệ',
  'Mes.Item.Invalid.High': 'vật phẩm không hợp lệ Cao',
  'Mes.Item.Invalid.Abscissa': 'vật phẩm Abscissa không hợp lệ',
  'Mes.Item.Invalid.Ordinate': 'Mặt hàng không hợp lệ',
  'Mes.Item.Invalid.ConfigurationObj': 'Đối tượng cấu hình vật phẩm không hợp lệ',
  'Mes.Item.Invalid.Configuration': 'Cấu hình vật phẩm không hợp lệ',
  'Mes.Item.Invalid.Type': 'Loại vật phẩm không hợp lệ',
  'Mes.Item.Invalid.Price': 'Giá mặt hàng không hợp lệ',
  'Mes.Item.Invalid.Quantity': 'Số lượng mặt hàng không hợp lệ',
  'Mes.Item.Invalid.Name': 'Tên mặt hàng không hợp lệ',
  'Mes.Item.Invalid.Code': 'Mã hàng không hợp lệ',
  'Mes.Item.Repeated.Id': 'Id vật phẩm lặp lại',
  'Mes.Item.Repeated.Events': 'Sự kiện vật phẩm lặp đi lặp lại',
  'Mes.Item.Repeated.OwnPreferentials': 'Ưu đãi riêng của mặt hàng lặp lại',
  'Mes.Item.Repeated.Preferentials': 'Ưu đãi mặt hàng lặp lại',
  'Mes.Item.Repeated.ItemStorages': 'Kho vật phẩm vật phẩm lặp đi lặp lại',
  'Mes.Item.Repeated.SoldQuantity': 'Mặt hàng lặp lại Số lượng đã bán',
  'Mes.Item.Repeated.SortType': 'Loại vật phẩm sắp xếp lặp lại',
  'Mes.Item.Repeated.BusinessStatus': 'Tình trạng kinh doanh mặt hàng lặp lại',
  'Mes.Item.Repeated.GenderType': 'vật phẩm lặp lại Loại giới tính',
  'Mes.Item.Repeated.ItemGroup': 'Nhóm vật phẩm vật phẩm lặp lại',
  'Mes.Item.Repeated.ItemGroupId': 'Id nhóm vật phẩm vật phẩm lặp lại',
  'Mes.Item.Repeated.Collection': 'Bộ sưu tập vật phẩm lặp lại',
  'Mes.Item.Repeated.CollectionId': 'Id bộ sưu tập vật phẩm lặp lại',
  'Mes.Item.Repeated.Overlay': 'Lớp phủ vật phẩm lặp lại',
  'Mes.Item.Repeated.Thumbnail': 'Hình thu nhỏ vật phẩm lặp lại',
  'Mes.Item.Repeated.High': 'vật phẩm lặp lại cao',
  'Mes.Item.Repeated.Abscissa': 'vật phẩm Abscissa lặp đi lặp lại',
  'Mes.Item.Repeated.Ordinate': 'vật phẩm lặp đi lặp lại',
  'Mes.Item.Repeated.ConfigurationObj': 'vật phẩm cấu hình lặp lại Obj',
  'Mes.Item.Repeated.Configuration': 'Cấu hình vật phẩm lặp lại',
  'Mes.Item.Repeated.Type': 'Loại vật phẩm lặp lại',
  'Mes.Item.Repeated.Price': 'Giá mặt hàng lặp lại',
  'Mes.Item.Repeated.Quantity': 'Số lượng mặt hàng lặp đi lặp lại',
  'Mes.Item.Repeated.Name': 'Tên vật phẩm lặp đi lặp lại',
  'Mes.Item.Repeated.Code': 'Mã hàng lặp lại',
  'Mes.Item.NotFound.Id': 'Id vật phẩm không tìm thấy',
  'Mes.Item.NotFound.Events': 'Sự kiện không tìm thấy vật phẩm',
  'Mes.Item.NotFound.OwnPreferentials': 'Không tìm thấy vật phẩm Ưu đãi riêng',
  'Mes.Item.NotFound.Preferentials': 'Không Tìm Thấy vật phẩm Ưu Đãi',
  'Mes.Item.NotFound.ItemStorages': 'Không tìm thấy vật phẩm Kho vật phẩm',
  'Mes.Item.NotFound.SoldQuantity': 'Không Tìm Thấy Hàng Đã Bán Số Lượng',
  'Mes.Item.NotFound.SortType': 'Không tìm thấy vật phẩm Loại sắp xếp',
  'Mes.Item.NotFound.BusinessStatus': 'Không tìm thấy mặt hàng Tình trạng kinh doanh',
  'Mes.Item.NotFound.GenderType': 'Không tìm thấy vật phẩm Loại giới tính',
  'Mes.Item.NotFound.ItemGroup': 'Không tìm thấy vật phẩm Nhóm vật phẩm',
  'Mes.Item.NotFound.ItemGroupId': 'Id nhóm vật phẩm không tìm thấy',
  'Mes.Item.NotFound.Collection': 'Bộ sưu tập vật phẩm không tìm thấy',
  'Mes.Item.NotFound.CollectionId': 'Id bộ sưu tập vật phẩm không tìm thấy',
  'Mes.Item.NotFound.Overlay': 'Lớp phủ vật phẩm không tìm thấy',
  'Mes.Item.NotFound.Thumbnail': 'Hình thu nhỏ không tìm thấy vật phẩm',
  'Mes.Item.NotFound.High': 'Không Tìm Thấy Hàng Cao',
  'Mes.Item.NotFound.Abscissa': 'Không tìm thấy vật phẩm Abscissa',
  'Mes.Item.NotFound.Ordinate': 'Không tìm thấy vật phẩm',
  'Mes.Item.NotFound.ConfigurationObj': 'Không tìm thấy vật phẩm Cấu hình Obj',
  'Mes.Item.NotFound.Configuration': 'Cấu hình vật phẩm không tìm thấy',
  'Mes.Item.NotFound.Type': 'Loại hàng không tìm thấy',
  'Mes.Item.NotFound.Price': 'Giá hàng không tìm thấy',
  'Mes.Item.NotFound.Quantity': 'Không tìm thấy vật phẩm Số lượng',
  'Mes.Item.NotFound.Name': 'Tên vật phẩm không tìm thấy',
  'Mes.Item.NotFound.Code': 'Mã hàng không tìm thấy',
  'Mes.Item.NotFound': 'Không tìm thấy vật phẩm',
  'Mes.Item.WasUsed.Id': 'ID mặt hàng đã được sử dụng',
  'Mes.Item.WasUsed.Events': 'Vật phẩm đã qua sử dụng Sự kiện',
  'Mes.Item.WasUsed.OwnPreferentials': 'Hàng đã qua sử dụng Ưu đãi riêng',
  'Mes.Item.WasUsed.Preferentials': 'Ưu đãi vật phẩm đã qua sử dụng',
  'Mes.Item.WasUsed.ItemStorages': 'Đã qua sử dụng Vật phẩm Kho vật phẩm',
  'Mes.Item.WasUsed.SoldQuantity': 'Hàng đã qua sử dụng Số lượng đã bán',
  'Mes.Item.WasUsed.SortType': 'Vật phẩm đã qua sử dụng Loại sắp xếp',
  'Mes.Item.WasUsed.BusinessStatus': 'Mặt hàng đã qua sử dụng Tình trạng kinh doanh',
  'Mes.Item.WasUsed.GenderType': 'Đã được sử dụng vật phẩm Giới tính Loại',
  'Mes.Item.WasUsed.ItemGroup': 'Đã qua sử dụng Vật phẩm Nhóm vật phẩm',
  'Mes.Item.WasUsed.ItemGroupId': 'Id nhóm vật phẩm đã được sử dụng',
  'Mes.Item.WasUsed.Collection': 'Bộ sưu tập vật phẩm đã qua sử dụng',
  'Mes.Item.WasUsed.CollectionId': 'Id bộ sưu tập vật phẩm đã được sử dụng',
  'Mes.Item.WasUsed.Overlay': 'Lớp phủ vật phẩm đã được sử dụng',
  'Mes.Item.WasUsed.Thumbnail': 'Hình thu nhỏ vật phẩm đã qua sử dụng',
  'Mes.Item.WasUsed.High': 'Hàng đã qua sử dụng Cao',
  'Mes.Item.WasUsed.Abscissa': 'Vật dụng đã qua sử dụng',
  'Mes.Item.WasUsed.Ordinate': 'Vật phẩm đã được sử dụng',
  'Mes.Item.WasUsed.ConfigurationObj': 'vật phẩm cấu hình Obj đã được sử dụng',
  'Mes.Item.WasUsed.Configuration': 'Cấu hình vật phẩm đã được sử dụng',
  'Mes.Item.WasUsed.Type': 'Đã qua sử dụng Loại sản phẩm',
  'Mes.Item.WasUsed.Price': 'Hàng đã qua sử dụng Giá',
  'Mes.Item.WasUsed.Quantity': 'Hàng đã qua sử dụng Số lượng',
  'Mes.Item.WasUsed.Name': 'Tên mặt hàng đã được sử dụng',
  'Mes.Item.WasUsed.Code': 'Mã hàng đã qua sử dụng',
  'Mes.Item.WasUsed': 'Vật phẩm đã được sử dụng',
  'Mes.Gift.Delete.Failed': 'Xóa quà tặng không thành công',
  'Mes.Gift.Delete.Successfully': 'Xóa quà tặng thành công',
  'Mes.Gift.Distribute.Failed': 'Phân phối quà tặng không thành công',
  'Mes.Gift.Distribute.Successfully': 'Phát quà thành công',
  'Mes.Gift.Search.Failed': 'Tìm kiếm quà tặng không thành công',
  'Mes.Gift.Search.Successfully': 'Kiếm Quà Thành Công',
  'Mes.Gift.ViewList.Failed': 'Xem danh sách quà tặng không thành công',
  'Mes.Gift.ViewList.Successfully': 'Xem Danh Sách Quà Tặng Thành Công',
  'Mes.Gift.Purchase.Failed': 'Mua quà không thành công',
  'Mes.Gift.Purchase.Successfully': 'Mua quà thành công',
  'Mes.Gift.ViewDetail.Failed': 'Xem chi tiết Quà tặng không thành công',
  'Mes.Gift.ViewDetail.Successfully': 'Xem Chi Tiết Quà Tặng Thành Công',
  'Mes.Gift.Update.Failed': 'Cập nhật quà tặng không thành công',
  'Mes.Gift.Update.Successfully': 'Cập nhật quà tặng thành công',
  'Mes.Gift.Create.Failed': 'Tạo quà tặng không thành công',
  'Mes.Gift.Create.Successfully': 'Tạo quà tặng thành công',
  'Mes.Gift.Expired.Order': 'Thứ tự quà tặng đã hết hạn',
  'Mes.Gift.Expired.Kind': 'Loại quà hết hạn',
  'Mes.Gift.Expired.Illustration': 'Hình Minh Họa Quà Tặng Hết Hạn',
  'Mes.Gift.Expired.WeekQuantity': 'Số lượng tuần quà tặng đã hết hạn',
  'Mes.Gift.Expired.Quantity': 'Số lượng quà tặng hết hạn',
  'Mes.Gift.Expired.Code': 'Mã quà tặng hết hạn',
  'Mes.Gift.AlreadyExist.Id': 'Id quà tặng đã tồn tại',
  'Mes.Gift.AlreadyExist.Name': 'Tên quà tặng đã tồn tại',
  'Mes.Gift.AlreadyExist.Code': 'Mã quà tặng đã tồn tại',
  'Mes.Gift.NotEnough.UsedQuantity': 'Quà tặng không đủ số lượng đã sử dụng',
  'Mes.Gift.NotEnough.Order': 'Thứ tự quà tặng không đủ',
  'Mes.Gift.NotEnough.Kind': 'Không Đủ Loại Quà Tặng',
  'Mes.Gift.NotEnough.Illustration': 'Minh họa quà tặng không đủ',
  'Mes.Gift.NotEnough.WeekQuantity': 'Số Lượng Tuần Quà Tặng Không Đủ',
  'Mes.Gift.NotEnough.Quantity': 'Số lượng quà tặng không đủ',
  'Mes.Gift.NotEnough.Type': 'Không đủ loại quà tặng',
  'Mes.Gift.NotEnough.AwardName': 'Tên giải thưởng quà tặng không đủ',
  'Mes.Gift.NotEnough.Name': 'Tên quà tặng không đủ',
  'Mes.Gift.NotEnough.Code': 'Mã quà tặng không đủ',
  'Mes.Gift.OverLength.AwardName': 'Tên giải thưởng quà tặng dài hơn',
  'Mes.Gift.OverLength.Name': 'Tên quà tặng quá dài',
  'Mes.Gift.OverLength.Code': 'Mã quà tặng quá dài',
  'Mes.Gift.Required.Id': 'Id quà tặng bắt buộc',
  'Mes.Gift.Required.Order': 'Thứ tự quà tặng bắt buộc',
  'Mes.Gift.Required.Kind': 'Loại quà tặng bắt buộc',
  'Mes.Gift.Required.Illustration': 'Hình minh họa quà tặng bắt buộc',
  'Mes.Gift.Required.WeekQuantity': 'Số lượng tuần quà tặng bắt buộc',
  'Mes.Gift.Required.Quantity': 'Số lượng quà tặng bắt buộc',
  'Mes.Gift.Required.Type': 'Loại quà tặng bắt buộc',
  'Mes.Gift.Required.AwardName': 'Tên phần thưởng quà tặng bắt buộc',
  'Mes.Gift.Required.Name': 'Tên món quà bắt buộc',
  'Mes.Gift.Required.Code': 'Mã quà tặng bắt buộc',
  'Mes.Gift.MustBeEmpty.Id': 'Id quà tặng phải trống',
  'Mes.Gift.MustBeEmpty.UsedQuantity': 'Phải là quà tặng rỗng Số lượng đã sử dụng',
  'Mes.Gift.MustBeEmpty.Order': 'Đơn đặt hàng quà tặng phải trống',
  'Mes.Gift.MustBeEmpty.Kind': 'Phải là loại quà tặng rỗng',
  'Mes.Gift.MustBeEmpty.Illustration': 'Phải Là Hình Minh Họa Món Quà Rỗng',
  'Mes.Gift.MustBeEmpty.WeekQuantity': 'Số lượng tuần quà phải rỗng',
  'Mes.Gift.MustBeEmpty.Quantity': 'Số lượng quà tặng phải trống',
  'Mes.Gift.MustBeEmpty.Type': 'Phải là loại quà tặng rỗng',
  'Mes.Gift.MustBeEmpty.AwardName': 'Tên phần thưởng quà tặng phải trống',
  'Mes.Gift.MustBeEmpty.Name': 'Tên quà tặng phải trống',
  'Mes.Gift.MustBeEmpty.Code': 'Mã quà tặng phải trống',
  'Mes.Gift.Invalid.Id': 'Id quà tặng không hợp lệ',
  'Mes.Gift.Invalid.UsedQuantity': 'Quà tặng không hợp lệ Số lượng đã sử dụng',
  'Mes.Gift.Invalid.Order': 'Thứ tự quà tặng không hợp lệ',
  'Mes.Gift.Invalid.Kind': 'Loại quà tặng không hợp lệ',
  'Mes.Gift.Invalid.Illustration': 'Hình minh họa quà tặng không hợp lệ',
  'Mes.Gift.Invalid.WeekQuantity': 'Số lượng tuần quà tặng không hợp lệ',
  'Mes.Gift.Invalid.Quantity': 'Số lượng quà tặng không hợp lệ',
  'Mes.Gift.Invalid.Type': 'Loại quà tặng không hợp lệ',
  'Mes.Gift.Invalid.AwardName': 'Tên phần thưởng quà tặng không hợp lệ',
  'Mes.Gift.Invalid.Name': 'Tên quà tặng không hợp lệ',
  'Mes.Gift.Invalid.Code': 'Mã quà tặng không hợp lệ',
  'Mes.Gift.Repeated.Id': 'Id quà tặng lặp lại',
  'Mes.Gift.Repeated.UsedQuantity': 'Quà tặng lặp lại Số lượng đã sử dụng',
  'Mes.Gift.Repeated.Order': 'Thứ tự quà tặng lặp lại',
  'Mes.Gift.Repeated.Kind': 'Loại quà lặp đi lặp lại',
  'Mes.Gift.Repeated.Illustration': 'Hình minh họa quà tặng lặp đi lặp lại',
  'Mes.Gift.Repeated.WeekQuantity': 'Tuần quà tặng lặp lại Số lượng',
  'Mes.Gift.Repeated.Quantity': 'Số lượng quà tặng lặp lại',
  'Mes.Gift.Repeated.Type': 'Loại quà tặng lặp lại',
  'Mes.Gift.Repeated.AwardName': 'Tên phần thưởng quà tặng lặp lại',
  'Mes.Gift.Repeated.Name': 'Tên quà tặng lặp lại',
  'Mes.Gift.Repeated.Code': 'Mã quà tặng lặp lại',
  'Mes.Gift.NotFound.Id': 'Id quà tặng không tìm thấy',
  'Mes.Gift.NotFound.Order': 'Thứ tự quà tặng không tìm thấy',
  'Mes.Gift.NotFound.Kind': 'Không tìm thấy loại quà tặng',
  'Mes.Gift.NotFound.Illustration': 'Hình minh họa quà tặng không tìm thấy',
  'Mes.Gift.NotFound.WeekQuantity': 'Số lượng tuần quà tặng không tìm thấy',
  'Mes.Gift.NotFound.Quantity': 'Số lượng quà tặng không tìm thấy',
  'Mes.Gift.NotFound.Type': 'Loại quà tặng không tìm thấy',
  'Mes.Gift.NotFound.AwardName': 'Tên phần thưởng quà tặng không tìm thấy',
  'Mes.Gift.NotFound.Name': 'Tên quà tặng không tìm thấy',
  'Mes.Gift.NotFound.Code': 'Mã quà tặng không tìm thấy',
  'Mes.Gift.NotFound': 'Không tìm thấy Quà tặng Số lượng đã sử dụng',
  'Mes.Gift.WasUsed.Id': 'Id quà tặng đã được sử dụng',
  'Mes.Gift.WasUsed.UsedQuantity': 'Đã sử dụng Quà tặng Số lượng đã sử dụng',
  'Mes.Gift.WasUsed.Order': 'Đơn đặt hàng quà tặng đã được sử dụng',
  'Mes.Gift.WasUsed.Kind': 'Đã qua sử dụng Loại quà tặng',
  'Mes.Gift.WasUsed.Illustration': 'Hình Minh Họa Quà Tặng Được Sử Dụng',
  'Mes.Gift.WasUsed.WeekQuantity': 'Đã được sử dụng Quà tặng Tuần Số lượng',
  'Mes.Gift.WasUsed.Quantity': 'Số lượng quà tặng đã qua sử dụng',
  'Mes.Gift.WasUsed.Type': 'Đã qua sử dụng Loại quà tặng',
  'Mes.Gift.WasUsed.AwardName': 'Tên quà tặng đã được sử dụng',
  'Mes.Gift.WasUsed.Name': 'Tên quà tặng đã được sử dụng',
  'Mes.Gift.WasUsed.Code': 'Mã quà tặng đã được sử dụng',
  'Mes.Gift.WasUsed': 'Quà tặng đã được sử dụng',
  'Mes.Gift.Blocked': 'Quà tặng bị khóa',
  'Mes.Gift.Invalid.Amount': 'Số lượng quà tặng không hợp lệ',
  'Mes.Gift.Required.Amount': 'Sô lượng quà tặng bắt buộc',
  'Mes.Gift.MustBeDivisible.Quantity':
    'Sô lượng quà tặng phải chia hết cho số lượng quà tặng mỗi tuần',
  'Mes.Voucher.Repeated.Ids': 'Mã định danh Voucher bị lặp lại',
  'Mes.Voucher.Required.Ids': 'Mã định danh Voucher là bắt buộc',
  'Mes.Voucher.Invalid.Ids': 'Có mã định danh Voucher không hợp lệ',
  'Mes.Voucher.Repeated.Code': 'Mã Voucher bị lặp lại',
  'Mes.Voucher.Required.OperationStatus': 'Trạng thái hoạt động Voucher là bắt buộc',
  'Mes.Voucher.Invalid.OperationStatus': 'Trạng thái hoạt động Voucher không hợp lệ',
  'Mes.Voucher.NotEnough.Quantity': 'Số lượng Voucher không không đủ',
  'Mes.Voucher.WasUsed': 'Voucher đã được sử dụng',
  'Mes.Week.NotEnough.Time': 'Chưa đến thời gian phát thưởng tuần',
  'Mes.Week.WasDistribute.Gift': 'Tuần đã phát thưởng',
  'Mes.Setting.Required.Value': 'Giá trị của cài đặt là bắt buộc',
  'Mes.Setting.Invalid.Value': 'Giá trị của cài đặt không hợp lệ',
  'Mes.Setting.Update.Failed': 'Cập nhật cài đặt không thành công',
  'Mes.RankingSetting.Invalid.EndTime': 'Thời gian kết thúc chốt bảng xếp hạng không hợp lệ',
  'Mes.RankingSetting.Invalid.DayOfWeek': 'Thứ không hợp lệ',
  'Mes.RankingSetting.Started': 'Thời gian chốt thưởng đã bắt đầu',

  //======================error==============
  //======================error==============
  'Mes.Error.Delete.Failed': 'Xóa lỗi không thành công',
  'Mes.Error.Delete.Successfully': 'Xóa lỗi thành công',
  'Mes.Error.Distribute.Failed': 'Lỗi phân phối không thành công',
  'Mes.Error.Distribute.Successfully': 'Phân phối lỗi thành công',
  'Mes.Error.Search.Failed': 'Lỗi tìm kiếm không thành công',
  'Mes.Error.Search.Successfully': 'Tìm kiếm lỗi thành công',
  'Mes.Error.ViewList.Failed': 'Lỗi xem danh sách không thành công',
  'Mes.Error.ViewList.Successfully': 'Xem danh sách lỗi thành công',
  'Mes.Error.ViewDetail.Failed': 'Xem chi tiết Lỗi không thành công',
  'Mes.Error.ViewDetail.Successfully': 'Xem chi tiết Lỗi thành công',
  'Mes.Error.Update.Failed': 'Lỗi cập nhật không thành công',
  'Mes.Error.Update.Successfully': 'Cập nhật lỗi thành công',
  'Mes.Error.Create.Failed': 'Tạo lỗi không thành công',
  'Mes.Error.Create.Successfully': 'Tạo lỗi thành công',
  'Mes.Error.OverLength.Content': 'Nội dung lỗi quá dài',
  'Mes.Error.OverLength.Contact': 'Liên hệ quá dài',
  'Mes.Error.Required.Id': 'Id lỗi bắt buộc',
  'Mes.Error.Required.DeviceId': 'Id thiết bị bắt buộc',
  'Mes.Error.Required.ErrorTypeId': 'Id loại lỗi bắt buộc',
  'Mes.Error.Required.Status': 'Tình trạng bắt buộc',
  'Mes.Error.Required.Content': 'Nội dung lỗi bắt buộc',
  'Mes.Error.Required.Contact': 'Liên hệ bắt buộc',
  'Mes.Error.Invalid.Id': 'Id không hợp lệ',
  'Mes.Error.Invalid.DeviceId': 'Id thiết bị không hợp lệ',
  'Mes.Error.Invalid.ErrorTypeId': 'Id loại không hợp lệ',
  'Mes.Error.Invalid.Status': 'Trạng thái không hợp lệ',
  'Mes.Error.Invalid.Content': 'Nội dung không hợp lệ',
  'Mes.Error.Invalid.Contact': 'Liên hệ không hợp lệ',
  'Mes.Error.Repeated.Id': 'Id lỗi lặp lại',
  'Mes.Error.Repeated.DeviceId': 'Id thiết bị lặp đi lặp lại',
  'Mes.Error.Repeated.ErrorTypeId': 'Id loại lỗi lặp lại',
  'Mes.Error.NotFound.Id': 'Không tìm thấy',
  'Mes.Error.NotFound.DeviceId': 'Không tìm thấy Id thiết bị lỗi',
  'Mes.Error.NotFound.ErrorTypeId': 'Không Tìm thấy Id Loại Lỗi',
  'Mes.Error.NotFound': 'Không tìm thấy lỗi',
  'Mes.VoucherType.PaymentProcessingFailed': 'Xử lí giao dịch bị lỗi',
  'Mes.VoucherType.Redeem.Failed': 'Quy đổi không thành công',
  'Mes.VoucherType.Blocked': 'Quy đổi voucher này đã ngưng sử dụng',
  'Mes.VoucherType.Redeem.Successfully': 'Quy đổi thành công',
  'Mes.VoucherType.Delete.Failed': 'Xóa không thành công',
  'Mes.VoucherType.Delete.Successfully': 'Xóa voucher quy đổi thành công',
  'Mes.VoucherType.Search.Successfully': 'Tìm kiếm thành công',
  'Mes.VoucherType.ViewList.Successfully': 'Xem Danh Sách Thành Công',
  'Mes.VoucherType.ViewDetail.Successfully': 'Xem Chi Tiết Thành Công',
  'Mes.VoucherType.Update.Failed': 'Cập nhật không thành công',
  'Mes.VoucherType.Update.Successfully': 'Cập nhật voucher quy đổi thành công',
  'Mes.VoucherType.Create.Failed': 'Tạo voucher quy đổi không thành công',
  'Mes.VoucherType.Create.Successfully': 'Tạo voucher quy đổi thành công',
  'Mes.VoucherType.Expired': 'Hết hạn',
  'Mes.VoucherType.NotEnough.Endtime': 'Chưa tới thời gian kết thúc',
  'Mes.VoucherType.NotEnough.StartTime': 'Chưa tới thời gian bắt đầu',
  'Mes.VoucherType.NotEnough.Quantity': 'Không Đủ Số Lượng',
  'Mes.VoucherType.NotEnough.Amount': 'Số tiền nhận được phải lớn hơn hoặc bằng 1.000 VNĐ',
  'Mes.VoucherType.NotEnough.Price': 'Không đủ coin',
  'Mes.VoucherType.NotEnough.Content': 'Không Đủ Nội Dung',
  'Mes.VoucherType.OverLength.Endtime': 'Quá thời hạn thời gian kết thúc',
  'Mes.VoucherType.OverLength.UsedQuantity': 'Quá số lượng đã sử dụng',
  'Mes.VoucherType.OverLength.Quantity': 'Quá số lượng',
  'Mes.VoucherType.OverLength.Amount': 'Quá số tiền',
  'Mes.VoucherType.OverLength.Price': 'Qúa số coin quy định',
  'Mes.VoucherType.OverLength.Content': 'Nội dung quá dài',
  'Mes.VoucherType.OverLength.Name': 'Tên quá dài',
  'Mes.VoucherType.Required.Id': 'Yêu cầu VoucherTypeId',
  'Mes.VoucherType.Required.BusinessStatus': 'Bắt buộc nhập Tình trạng kinh doanh',
  'Mes.VoucherType.Required.SortType': 'Bắt buộc nhập Loại sắp xếp',
  'Mes.VoucherType.Required.Endtime': 'Bắt buộc nhập Thời gian kết thúc',
  'Mes.VoucherType.Required.StartTime': 'Bắt buộc nhập Thời gian bắt đầu',
  'Mes.VoucherType.Required.UsedQuantity': 'Bắt buộc nhập Số lượng sử dụng',
  'Mes.VoucherType.Required.Quantity': 'Bắt buộc nhập Số lượng',
  'Mes.VoucherType.Required.Amount': 'Bắt buộc nhập Số tiền',
  'Mes.VoucherType.Required.Price': 'Bắt buộc nhập Giá coin',
  'Mes.VoucherType.Required.Content': 'Bắt buộc nhập Nội dung',
  'Mes.VoucherType.Required.Name': 'Bắt buộc nhập Tên',
  'Mes.VoucherType.Invalid.Id': 'Id không hợp lệ',
  'Mes.VoucherType.Invalid.BusinessStatus': 'Tình trạng kinh doanh không hợp lệ',
  'Mes.VoucherType.Invalid.SortType': 'Loại sắp xếp không hợp lệ',
  'Mes.VoucherType.Invalid.Endtime': 'Thời gian kết thúc không hợp lệ',
  'Mes.VoucherType.Invalid.StartTime': 'Thời gian bắt đầu không hợp lệ',
  'Mes.VoucherType.Invalid.Quantity': 'Số lượng không hợp lệ',
  'Mes.VoucherType.Invalid.Amount': 'Số tiền không hợp lệ',
  'Mes.VoucherType.Invalid.Price': 'Giá không hợp lệ',
  'Mes.VoucherType.Invalid.Content': 'Nội dung không hợp lệ',
  'Mes.VoucherType.Invalid.Name': 'Tên không hợp lệ',
  'Mes.VoucherType.Repeated.Id': 'Id lặp lại',
  'Mes.VoucherType.NotFound.Id': 'Không tìm thấy',
  'Mes.VoucherType.NotFound': 'Không tìm thấy',
  'Mes.VoucherType.WasUsed': 'Voucher quy đổi đã được sử dụng',
  'Mes.VoucherType.NotExistOrNotActive.PhoneNumber':
    'Số điện thoại không tồn tại hoặc chưa được kích hoạt',
  'Mes.VoucherType.Restricted.PhoneNumber': 'Số điện thoại bị hạn chế',

  'Mes.Item.Revoke.Successfully': 'Thu hồi vật phẩm thành công',
  'Mes.Gift.Revoke.Successfully': 'Thu hồi quà thành công',
  'Mes.Event.Update.Successfully': 'Cập nhật chương trình thành công',
  'Mes.Event.WasUsed': 'Chương trình đã phát thưởng không thể xóa',
  'Mes.Event.Delete.Successfully': 'Xóa chương trình thành công',
  'Mes.Preferential.WasUsed': 'Ưu đãi trùng thời gian',
  'Mes.Preferential.Invalid.Preferentials': 'Ưu đãi không hợp lệ',
  'Mes.Preferential.OverLength.CollectionTime': 'Thời gian ưu đãi không hợp lệ',

  'Mes.Event.Create.Successfully': 'Thêm chương trình thành công',
  'Mes.Device.Create.Successfully': 'Thêm thiết bị thành công',
  'Mes.Device.Update.Successfully': 'Cập nhật thiết bị thành công',
  'Mes.Device.Delete.Successfully': 'Xóa thiết bị thành công',
  'Mes.Device.AlreadyExist.CollectHistories': 'Không thể xóa thiết bị đã thu thập chai',
  'Mes.Gift.Transfer.Successfully': 'Chuyển quà thành công',
  'Mes.Voucher.Import.Successfully': 'Nhập voucher thành công',
  'Mes.Voucher.Delete.Successfully': 'Xóa voucher thành công',
  'Mes.Voucher.Update.Successfully': 'Cập nhật voucher thành công',
  'Mes.ItemGroup.Update.Successfully': 'Cập nhật nhóm vật phẩm thành công',
  'Mes.User.WasUsed': 'Không thể xóa tài khoản đang hoạt động',
  'Mes.Setting.Update.Successfully': 'Cập nhật cài đặt thành công',
  'Mes.User.NotFound.Email': 'Không tìm thấy email',
  'Mes.Item.DeleteCollectionHasItems.Failed': 'Không thể xóa bộ sưu tập đã có vật phẩm',
  'Mes.Device.AlreadyExist.Code': 'Mã thiết bị đã tồn tại',
  'Mes.Item.DeleteItemGroupHasItems.Failed': 'Không thể xóa nhóm đã có vật phẩm',
  'Mes.Event.AlreadyExist.Code': 'Mã chương trình đã tồn tại',
  'Mes.RankingSetting.Invalid.StartTime': 'Thời gian bắt đầu không hợp lệ',
  'Mes.RankingSetting.NotAllowUpdate.StartTime': 'Không cho phép thời gian bắt đầu cập nhật',
  'Mes.RankingSetting.Invalid.ClosingTime': 'Thời gian chốt thưởng không hợp lệ',
  'Mes.DeviceGroup.Create.Successfully': 'Tạo nhóm thiết bị thành công',
  'Mes.DeviceGroup.Update.Successfully': 'Cập nhật nhóm thiết bị thành công',
  'Mes.DeviceGroup.Delete.Successfully': 'Xóa nhóm thiết bị thành công',

  'Mes.DeviceGroup.AlreadyExist.Devices': 'Nhóm thiết bị đã được sử dụng',
  'Mes.AiRankingSetting.CanNotBeChanged.StartTime': 'Không thể thay đổi thời gian bắt đầu',

  'Mes.User.Wrong.Password': 'Mật khẩu không chính xác',
};
