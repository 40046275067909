import React from 'react';

import { IRouter } from '@routers/interface';

export const routerFormDeviceAdd: IRouter = {
  path: '/device/add',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'device-add.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
};

export const routerFormDeviceEdit: IRouter = {
  path: '/device/:id',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'device-edit.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
};
