export default {
  'dashboard.name': 'Dashboard',
  'dashboard.customer.active': ' customers are active',
  'dashboard.customer.unActive': ' customers stop operating',
  'dashboard.item.active': ' items are doing business',
  'dashboard.item.unActive': ' business stop items',
  'dashboard.voucher.active': ' voucher type is doing business',
  'dashboard.voucher.unActive': ' voucher type does not do business',
  'dashboard.device.active': ' the machine is operating',
  'dashboard.device.unActive': ' the machine stops operating',
  'dashboard.rankingGift.active': ' gift given',
  'dashboard.rankingGift.unActive': ' remaining gift',
  'dashboard.otherGift.active': ' gift given',
  'dashboard.otherGift.unActive': ' remaining gift',
  'dashboard.title': 'Overview',

  'dashboard.customer': 'Client',
  'dashboard.item': 'Items',
  'dashboard.voucher': 'Voucher',
  'dashboard.device': 'Device',
  'dashboard.rankingGift': 'Ranking gifts',
  'dashboard.otherGift': 'Other gifts',

  'dashboard.customer.unit': 'customer',
  'dashboard.item.unit': 'items',
  'dashboard.voucher.unit': 'voucher type',
  'dashboard.device.unit': 'machine',
  'dashboard.gift.unit': 'gift',
  'dashboard.no-authentication': 'You have no right to view the rankings',

  'dashboard.rank.title': 'Weekly rankings',
  'chart.week': 'Week',
  'chart.month': 'Month',
  'chart.week.coin': 'week',
  'chart.month.coin': 'month',
  'chart.buyItem': 'Buy items',
  'chart.voucher': 'Voucher conversion',
  'chart.aqua': 'Bottle Aquafina',

  'chart.NotAqua': 'Brand is bottle',
  'chart.join': 'Participation',
  'chart.compare-to': 'compare to',
  'chart.before': 'before',
  'chart.join.customer': 'Customer participation',
  'chart.both.quantity': 'The amount of bottles collected',
  'chart.both': 'Bottle',
  'chart.empty': 'This week rank is empty',
  'chart.date': 'Day ',
};
