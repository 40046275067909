import dayjs from 'dayjs';

export class BaseEntity {
  id: string;

  createdAt?: string;

  constructor(base: Partial<BaseEntity>) {
    if (base.id == null) {
      this.id = '';
    } else {
      this.id = base.id;
    }
    if (base.createdAt == null) {
      this.createdAt = '';
    } else {
      this.createdAt = dayjs(base.createdAt).toISOString();
    }
  }

  static toList<T extends BaseEntity>(type: { new (b: Partial<T>): T }, p?: Array<Partial<T>>) {
    if (p == null) {
      return [];
    }
    return p.map(it => new type(it));
  }
}
