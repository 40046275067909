import React from 'react';

import { HomeOutlined } from '@ant-design/icons';
import { IRouter } from '@routers/interface';
import { PermissionEnum } from '@modules/permissions/entity';

export const routerDashboard: IRouter = {
  path: '/dashboard',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'dashboard.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    icon: <HomeOutlined />,
  },
  permissionCode: PermissionEnum.DashboardsView,
};
export const routerHome: IRouter = {
  path: '/',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'home.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  permissionCode: PermissionEnum.DashboardsView,
};
