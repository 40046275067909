export default {
  // First page
  'item.title': 'Manage items in store',
  'item.name': 'Item management',

  'item.filter.group': 'Item group',
  'item.filter.collection': 'Collection',
  'item.filter.bussiness-status': 'Business status',
  'item.filter.start': 'Start time',
  'item.filter.end': 'End time',
  'item.filter.duration': 'Duration',

  'item.table.img': 'Image',
  'item.table.id': 'Code',
  'item.table.name': 'Name ',
  'item.table.category': 'Item group',
  'item.table.green-coin': 'Dropped price / price',
  'item.table.quantity': 'Sold quantity / total',
  'item.table.collection': 'Collection',
  'item.table.duration': 'Duration',
  'item.table.bussiness-status': 'Business status',
  'item.table.bussiness-status.param':
    '{status,select, 1 {Available} 2 {Unavailable} 3 {Conclude} 4{Suspended} other {Undefined}}',
  'item.table.promotion-number': 'Promotion quantity',
  'item.table.bussiness-status.1': 'Available',
  'item.table.bussiness-status.2': 'Unavailable',
  'item.table.bussiness-status.3': 'Conclude',
  'item.table.bussiness-status.4': 'Suspended',

  'item.button.updateStatus': 'Update business status',
  'item.confirm.conclude': 'Are you sure you want to conclude this items?',
  'item.confirm.available': 'Are you sure you want to make it available?',
  'item.confirm.delete': 'Are you sure you want to delete this item?',

  // Add/Edit page
  'item.add': 'Add item',
  'item.update': 'Update item',

  'item.add.id': 'Item code',
  'item.add.id.placeholder': 'Please enter item code',
  'item.add.name': 'Item name',
  'item.add.name.placeholder': 'Please enter item name',
  'item.add.layer': 'Layer',
  'item.add.left': 'Left hand',
  'item.add.right': 'Right hand',
  'item.add.both': 'Both hand',
  'item.add.quantity': 'Quantity',
  'item.add.price': 'Item price',
  'item.add.price.placeholder': 'Please enter item price',
  'item.add.thumbnail': 'Thumbnail',
  'item.add.thumbnail.placeholder': 'Please enter thumbnail',
  'item.add.overlay': 'Overlay',
  'item.add.overlay.placeholder': 'Please enter overlay',
  'item.add.type': 'Preferential',
  'item.add.itemType': 'Item type',
  'item.add.type--1': 'Normal',
  'item.add.type--2': 'Mysterious',
  'item.add.type.placeholder': 'Please select preferential',
  'item.add.category': 'Item group',
  'item.add.category.placeholder': 'Please select item group',
  'item.add.collection': 'Collection',
  'item.add.collection.placeholder': 'Please select collection',
  'item.add.collection.add': 'Add collection',
  'item.add.duration': 'Duration',
  'item.add.gender': 'Gender',
  'item.add.gender--Male': 'Male',
  'item.add.gender--Female': 'Female',
  'item.add.gender--Unisex': 'Unisex',
  'item.add.sort-type': 'Sort type:',
  'item.add.sort-type.placeholder': 'Please enter sort type:',
  'item.add.sort-type--1': 'Default',
  'item.add.sort-type--2': 'Priority',
  'item.add.bussiness-status': 'Business status',
  'item.add.bussiness-status--available': 'Active',
  'item.add.bussiness-status--suspended': 'Suspended',
  'item.add.bussiness-status.placeholder': 'Please enter business status',

  // Info page
  'item.info.title': 'Item information',

  'item.info.id': 'Item code',
  'item.info.name': 'Item name',
  'item.info.quantity': 'Quantity',
  'item.info.price': 'Item price',
  'item.info.thumbnail': 'Thumbnail',
  'item.info.overlay': 'Overlay',
  'item.info.type': 'Item type',
  'item.info.type--2': 'Voucher',
  'item.info.type--1': 'Artifact',
  'item.info.category': 'Item group',
  'item.info.collection': 'Collection',
  'item.info.sort-type': 'Sort type',
  'item.info.sort-type--1': 'Default',
  'item.info.sort-type--2': 'Priority',
  'item.info.bussiness-status': 'Business status',
  'item.info.date-start': 'Start time',
  'item.info.date-end': 'End time',
  'item.info.sold': 'Quantity sold',
  'item.info.preferentialStatus.param':
    '{status , select, 1 {Not available} 2 {Active} 3 {Finish} other {Undefined}}',

  // History page
  'item.history.title': 'Item purchase history',

  'item.history.user-name': 'User name',
  'item.history.user-phone': 'Phone number',
  'item.history.purchase-time': 'Purchase time',
  'item.history.price-type': 'Purchase price type',
  'item.history.price-type.param':
    '{status , select, 1 {Original price} 2 {Discounted price} other {Undefined}}',
  'item.history.price': 'Purchase price (Green Coin)',

  'item.add.voucherType': 'Preferential',
  'item.add.placeholder.voucherType': 'Please choose the type of offer',
  'item.add.voucherType-discount': 'Discount',
  'item.add.voucherType-itemGift': 'Give item',
  'item.add.voucherType-gift': 'Gift',
  'item.add.quantityDiscount.placeholder': 'Please enter the number',
  'item.add.placeholder.percent': 'Please enter the percentage',
  'item.add.placeholder.groupItem': 'Please choose the item group',
  'item.add.placeholder.item': 'Please choose the item',
  'item.add.placeholder.giftType': 'Please choose the type of gift',
  'item.add.placeholder.gift': 'Please choose gifts',
  'btn-add-discount': 'Add discount',
  'item.add.info-title': '1. Information',
  'item.add.discount-title': '2. Preferential',
  'item.add-form.type': 'Preferential',
  'item.add.placeholder.type': 'Please choose the type of offer',
  'item.add-form.quantity': 'Quantity',
  'item.add-form.time': 'Duration',
  'item.add-form.discountPercent': 'The reduction rate (%)',
  'item.add-form.priceDiscount': 'Price after discount',
  'item.add.preferentialItemId': 'Items',
  'item.add.giftType': 'Gift type',
  'item.add.preferentialGiftId': 'Gift',
  'item.add.placeholder.Artifact': 'Artifacts',
  'item.add.placeholder.Voucher': 'Voucher',
};
