import React from 'react';

import { TrademarkOutlined } from '@ant-design/icons';
import { IRouter } from '@routers/interface';
import { PermissionEnum } from '@modules/permissions/entity';

export const routerRankTimeSetting: IRouter = {
  path: '/settings/rank-time-setting',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'rankTimeSetting.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  icon: <TrademarkOutlined />,
  permissionCode: PermissionEnum.SettingsView,
};
