import React from 'react';

import { ContainerOutlined } from '@ant-design/icons';
import { IRouter } from '@routers/interface';
import { PermissionEnum } from '@modules/permissions/entity';

export const routerCollection: IRouter = {
  path: '/settings/collection',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'collection.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  icon: <ContainerOutlined />,
  permissionCode: PermissionEnum.CollectionsView,
};

export const routerEditCollection: IRouter = {
  path: '/settings/collection/edit/:id',
  loader: React.lazy(() => import('./component/AddCollectionPage')),
  exact: true,
  name: 'updateCollection.title', //translate here for breadcrumb and sidebar
  permissionCode: PermissionEnum.CollectionsUpdate,
};
export const routerAddCollection: IRouter = {
  path: '/settings/collection/add',
  loader: React.lazy(() => import('./component/AddCollectionPage')),
  exact: true,
  name: 'addCollection.title', //translate here for breadcrumb and sidebar
  permissionCode: PermissionEnum.CollectionsCreate,
};
export const routerCollectionInfo: IRouter = {
  path: '/settings/collection/info/:id',
  loader: React.lazy(() => import('./component/CollectionInfoPage')),
  exact: true,
  name: 'collectionInfo.title', //translate here for breadcrumb and sidebar
  permissionCode: PermissionEnum.CollectionsViewDetail,
};
