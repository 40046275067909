export default {
  'setting.name': 'Setting',
  'roles.title': 'Role management',
  'roles.name': 'Name of role',
  'roles.rolePermissions': 'Permissions',
  'roles.permissionCodes': 'Permissions',
  'roles.createdAt': 'Created at',
  'roles.action': 'Action',
  'Per.Users.Create': 'Add user',
  'Per.Users.Delete': 'Delete user',
  'Per.Users.Edit': 'Edit user',
  'Per.Users.Export': 'Export user',
  'Per.Users.View': 'View user',
  'roles.create': 'Create role',
  'roles.information': 'Role information',
  'roles.update': 'Update role',
  'Mes.Users.Role.UpdateSuccessfully': 'Update role successfully',
  'Mes.Roles.Add.Successfully': 'Create role successfully',
  'roles.code': 'Role code',
  'roles.numberOfAssign': 'The number of accounts is assigned',
  'roles.description': 'Role description',

  'roles.delete.title': 'Delete the role',
  'roles.delete.content': 'Are you sure want to delete this Role?',

  Users: 'User',
  UsersView: 'View user',
  UsersViewDetail: 'See user details',
  UsersCreate: 'Create user',
  UsersUpdate: 'Update user',
  UsersDelete: 'Delete user',
  Roles: 'Role',
  RolesView: 'See the role',
  RolesViewDetail: 'See details',
  RolesCreate: 'Create role',
  RolesUpdate: 'Update the role',
  RolesDelete: 'Delete the role',
  Collections: 'Collection',
  CollectionsCreate: 'Create a collection',
  CollectionsDelete: 'Delete the collection',
  CollectionsView: 'See the collection',
  CollectionsViewDetail: 'See details of the collection',
  CollectionsUpdate: 'Update collection',
  Customers: 'Client',
  CustomersCreate: 'Create customers',
  CustomersDelete: 'Delete customers',
  CustomersView: 'See customers',
  CustomersViewDetail: 'See customer details',
  CustomersUpdate: 'Update customer',
  Devices: 'Device',
  DevicesCreate: 'Create equipment',
  DevicesDelete: 'Delete the device',
  DevicesView: 'View equipment',
  DevicesViewDetail: 'See device details',
  DevicesUpdate: 'Device update',
  DevicesCollectCreate: 'Collect',
  DevicesCollectView: 'View the history of collecting containers',
  DevicesFullContainerHistoryView: 'View the history full of barrels',
  Events: 'Program of item',
  EventsCreate: 'Create a program',
  EventsView: 'View item program',
  EventsViewDetail: 'See details of the item program',
  EventsUpdate: 'Update item program',
  EventsDelete: 'Delete the item program',
  Items: 'Items',
  ItemsCreate: 'Create items',
  ItemsDelete: 'Delete',
  ItemsView: 'See items',
  ItemsViewDetail: 'See details',
  ItemsUpdate: 'Update items',
  OtherGifts: 'Other gifts',
  OtherGiftsCreate: 'Create another gift',
  OtherGiftsDelete: 'Other gifts',
  OtherGiftsUpdate: 'Other gifts updates',
  OtherGiftsView: 'See other gifts',
  OtherGiftsViewDetail: 'See other gift details',
  OtherGiftsTransfer: 'Transfer other gifts',
  OtherGiftsDistribute: 'Give other gifts',
  RankingGifts: 'Ranking gifts',
  RankingGiftsCreate: 'Create gifts',
  RankingGiftsDelete: 'Delete rating gifts',
  RankingGiftsView: 'See gift gifts',
  RankingGiftsViewDetail: 'See detailed gifts',
  RankingGiftsUpdate: 'Ranking gift update',
  RankingGiftsTransfer: 'Transfer rankings gifts',
  Settings: 'Time to close the rankings',
  SettingsUpdate: 'Update time to close the rankings',
  SettingsView: 'See the time to close the rankings',
  Errors: 'Error',
  ErrorsUpdate: 'Error update',
  ErrorsView: 'See error',
  ErrorsViewDetail: 'See details',
  OtherGiftVouchers: 'Other gift vouchers',
  OtherGiftsVouchersCreate: 'Create a voucher for other gifts',
  OtherGiftsVouchersDelete: 'Delete vouchers for other gifts',
  OtherGiftsVouchersView: 'View voucher for other gifts',
  OtherGiftsVouchersUpdate: 'Update Voucher for other gifts',
  RankingGiftVouchers: 'Gift Voucher ranked',
  RankingGiftsVouchersCreate: 'Create a voucher for rating gifts',
  RankingGiftsVouchersDelete: 'Delete Voucher for Ranking Gifts',
  RankingGiftsVouchersView: 'See Voucher for Ranking Gifts',
  RankingGiftsVouchersUpdate: 'Update voucher for rating gifts',
  ItemGroups: 'Item group',
  ItemGroupsCreate: 'Create an item group',
  ItemGroupsDelete: 'Delete the item group',
  ItemGroupsView: 'See the item group',
  ItemGroupsViewDetail: 'See details of the item group',
  ItemGroupsUpdate: 'update the item group',
  ItemsChangeStatus: 'Change items status',
  ItemsPurchaseHistoriesView: 'View item purchase histories',
  CustomersPurchaseHistoriesRevoke: 'Customer Revoke',
  CustomersGiftsRevoke: 'Customer gift recovery',
  CustomersCollectionHistoriesView: 'See the history of customer score',
  CustomersVoucherStoragesView: 'See customer voucher history',
  CustomersPurchaseHistoriesView: 'See customer purchase history',
  CustomersLeaderboardHistoriesView: 'See customer ranking history',
  CustomersGiftStoragesView: 'See customer gift history',
  VoucherTypesViewDetail: 'See detailed voucher conversion',
  VoucherTypes: 'Voucher converted',
  VoucherTypesCreate: 'Create a conversion voucher',
  VoucherTypesDelete: 'Delete conversion voucher',
  VoucherTypesView: 'See voucher conversion',
  VoucherTypesUpdate: 'Update voucher conversion',
  DashboardsView: 'View dashboards',
  Dashboards: 'Dashboards',

  OverallView: 'View Overall rankings',
  Overall: 'Overall rankings',
  WeeklyView: 'See weekly rankings',
  Weekly: 'Weekly rankings',
  WeeklyDistribute: 'Give ranking gift manually',
  DevicesCollectionHistoriesView: 'See the history of collecting bottles',
  Reports: 'Report',
  ReportsView: 'View the general report',
  DeviceGroupsDelete: 'Delete device groups',
  DeviceGroupsView: 'View device group',
  DeviceGroupsCreate: 'Create device group',
  DeviceGroupsUpdate: 'Update device group',
  DeviceGroupsViewDetail: 'See detailed device group',
  DeviceGroups: 'Device group',
  Monthly: 'Monthly Ranking',
  MonthlyView: 'See monthly charts',
  Pets: 'Pet',
  PetsCreate: 'Create pet',
  PetsDelete: 'Delete pet',
  PetsView: 'View pet',
  PetsUpdate: 'Update pet',
  PetsViewDetail: 'View pet details',
  RaisingItem: 'Game product',
  RaisingItemCreate: 'Create game product',
  RaisingItemDelete: 'Delete game product',
  RaisingItemView: 'View game product',
  RaisingItemUpdate: 'Update game product',
  RaisingItemViewDetail: 'View game product details',
};
