import React from 'react';

import { SettingFilled } from '@ant-design/icons';
import { PermissionEnum } from '@modules/permissions/entity';
import { IRouter } from '@routers/interface';

import { routerDeviceGroups } from './DeviceGroups/router';
import { routerRankTimeSetting } from './RankTimeSetting/router';
import { routerUsers } from './Users/router';

export const routerSettings: IRouter = {
  path: null,
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'settings.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    icon: <SettingFilled />,
    activePath: '/settings',
  },
  permissionCode: [
    // PermissionEnum.CollectionsView,
    PermissionEnum.ItemGroupsView,
    PermissionEnum.UsersView,
    PermissionEnum.RolesView,
    PermissionEnum.SettingsView,
    PermissionEnum.DeviceGroupsView,
  ],
  routes: [
    // routerCollection,
    // routerCategory,
    routerDeviceGroups,
    routerUsers,
    routerRankTimeSetting,
  ],
};
