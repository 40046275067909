import React from 'react';

import { BookOutlined } from '@ant-design/icons';
import { IRouter } from '@routers/interface';
import { PermissionEnum } from '@modules/permissions/entity';

export const routerVoucher: IRouter = {
  path: '/voucher-type',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'voucher.router.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    icon: <BookOutlined />,
  },
  permissionCode: PermissionEnum.VoucherTypesView,
};
