import React from 'react';

import { GiftOutlined } from '@ant-design/icons';
import { IRouter } from '@routers/interface';
import { PermissionEnum } from '@modules/permissions/entity';

export const routerGift: IRouter = {
  path: '/gift',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'gift.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    icon: <GiftOutlined />,
  },
  permissionCode: [PermissionEnum.RankingGiftsView, PermissionEnum.OtherGiftsView],
  generatePath(listPermissions: string[]) {
    const permissionTabs = [PermissionEnum.RankingGiftsView, PermissionEnum.OtherGiftsView];
    if (permissionTabs.find(item => listPermissions?.includes(item))) {
      return `/gift?tab=${permissionTabs.find(item => listPermissions.includes(item))}`;
    }
    return '/gift';
  },
};

export const routerVoucherImport: IRouter = {
  path: '/gift/import/:type/:id',
  loader: React.lazy(() => import('./component/PageImport')),
  exact: true,
  name: 'ranking-gift-import.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  permissionCode: [PermissionEnum.OtherGiftsVouchersView, PermissionEnum.RankingGiftsVouchersView],
};

export const routerRankingGift: IRouter = {
  path: '/gift?tab=RankingGiftsView',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'gift.ranking.title', //translate here for breadcrumb and sidebar
  masterLayout: true,
  permissionCode: PermissionEnum.RankingGiftsView,
};
export const routerOtherGift: IRouter = {
  path: '/gift?tab=OtherGiftsView',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'gift.other.title', //translate here for breadcrumb and sidebar
  masterLayout: true,
  permissionCode: PermissionEnum.OtherGiftsView,
};
