import React from 'react';

import { UserOutlined } from '@ant-design/icons';
import { IRouter } from '@routers/interface';
import { PermissionEnum } from '@modules/permissions/entity';

export const routerAddGift: IRouter = {
  path: '/gift/:type/add',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'addGift.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  permissionCode: [PermissionEnum.OtherGiftsCreate, PermissionEnum.RankingGiftsCreate],
};

export const routerUpdateGift: IRouter = {
  path: '/gift/:type/update/:id',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'updateGift.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  permissionCode: [PermissionEnum.OtherGiftsUpdate, PermissionEnum.RankingGiftsUpdate],
};
