export default {
  'dashboard.name': 'Trang chủ',
  'dashboard.customer.active': ' khách hàng đang hoạt động',
  'dashboard.customer.unActive': ' khách hàng dừng hoạt động',
  'dashboard.item.active': ' vật phẩm đang kinh doanh',
  'dashboard.item.unActive': ' vật phẩm ngưng kinh doanh',
  'dashboard.voucher.active': ' loại voucher đang kinh doanh',
  'dashboard.voucher.unActive': ' loại voucher không kinh doanh',
  'dashboard.device.active': ' máy đang hoạt động',
  'dashboard.device.unActive': ' máy dừng hoạt động',
  'dashboard.rankingGift.active': ' quà đã tặng',
  'dashboard.rankingGift.unActive': ' quà còn lại',
  'dashboard.otherGift.active': ' quà đã tặng',
  'dashboard.otherGift.unActive': ' quà còn lại',
  'dashboard.title': 'Tổng quan',
  'dashboard.customer': 'Khách hàng',
  'dashboard.item': 'Vật phẩm',
  'dashboard.voucher': 'Voucher',
  'dashboard.device': 'Thiết bị',
  'dashboard.rankingGift': 'Quà tặng xếp hạng',
  'dashboard.otherGift': 'Quà tặng khác',

  'dashboard.customer.unit': 'khách hàng',
  'dashboard.item.unit': 'vật phẩm',
  'dashboard.voucher.unit': 'loại voucher',
  'dashboard.device.unit': 'máy',
  'dashboard.gift.unit': 'món quà',

  'dashboard.rank.title': 'Bảng xếp hạng tuần',
  'chart.week': 'Tuần',
  'chart.month': 'Tháng',
  'chart.week.coin': 'tuần',
  'chart.month.coin': 'tháng',
  'chart.buyItem': 'Mua vật phẩm',
  'chart.voucher': 'Quy đổi voucher',
  'chart.aqua': 'Chai Aquafina',
  'dashboard.no-authentication': 'Bạn không có quyền xem thông tin bảng xếp hạng',

  'chart.NotAqua': 'Chai của brand khác',
  'chart.join': 'lượt tham gia',
  'chart.compare-to': 'so với',
  'chart.before': 'trước',
  'chart.join.customer': 'Lượt tham gia của khách hàng',
  'chart.both.quantity': 'Lượng chai thu gom',
  'chart.both': 'chai',
  'chart.empty': 'Bảng xếp hạng tuần này trống',
  'chart.date': 'Ngày ',
};
