import React from 'react';

import { TeamOutlined } from '@ant-design/icons';
import { IRouter } from '@routers/interface';
import { PermissionEnum } from '@modules/permissions/entity';

export const routerCustomer: IRouter = {
  path: '/customer',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'customer.router.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    icon: <TeamOutlined />,
  },
  permissionCode: PermissionEnum.CustomersView,
};
