export const logo = require('./logo.png');
export const imgAvatar = require('./avatar.jpg');
export const languageVN = require('./vn.png');
export const languageEN = require('./en.png');
export const Img404 = require('./error.svg');
export const iconTotal = require('./iconTotal.png');
export const iconDevice = require('./iconDevice.png');
export const iconHeineken = require('./iconHeineken.png');
export const iconOther = require('./iconOther.png');
export const iconDot = require('./dotIcon.png');
export const exportFile = require('./ExportFile.png');
export const restart = require('./Restart.png');
export const iconCoin = require('./iconCoin.png');
export const iconUpload = require('./iconUpload.png');

export const iconCard1 = require('./icon-1.png');
export const iconCard2 = require('./icon-2.png');
export const iconCard3 = require('./icon-3.png');
export const iconCard4 = require('./icon-4.png');
export const ImgCoin = require('./coin.png');

export { default as ArrowLeftImg } from './arrow-left.png';
export { default as LogoutIcon } from './LogoutIcon.png';

export { default as vietnam } from './vn.png';
export { default as english } from './en.png';

export { default as ImgTop1 } from './1st_rank.png';
export { default as ImgTop2 } from './2nd_rank.png';
export { default as ImgTop3 } from './3rd_rank.png';
export { default as ImgBgRanking } from './bg-ranking.png';
