export default {
  'programme.name.router': 'Program of item',
  'programme.title': 'Program management',
  'programme.code': 'Program code',
  'programme.name': 'Program name',
  'programme.type': 'Program type',
  'programme.quantity': 'Number of incentives',
  'programme.status': 'Operating status',
  'programme.deadline': 'Duration',
  'programme.usedQuantity': 'Amount used',

  'programme.status.params':
    '{status,select, 1 {Active} 2 {Not yet to the deadline} 3 {Stop operation} 4 {Conclude} other {Unknown}}',
  'programme.type.scoreAchieve': 'Reward according to the point number of points',
  'programme.InProgress': 'Active',
  'programme.UnProgress': 'Not yet to the deadline',
  'programme.Suspended': 'Stop operation',
  'programme.Conclude': 'Conclude',
  'programme.delete.title': 'Delete program',
  'programme.delete.content': 'Are you sure you want to delete this program?',
  'programme.score': 'Regulation score',
  'programme.groupItem': 'Group item',
  'programme.itemId': 'Items',
  'programme-add.name': 'Add reward program by point number',
  'programme-edit.name': 'Update reward program by point number',
  'programme.placeholder.quantity': 'Please enter the preferential number',
  'programme.placeholder.score': 'Please enter the specified score',
  'programme-info.name': 'Program information on the point number',
  'errors.Processed': 'Processed',
  'errors.NoProcess': 'No process',
  'programme.type.params':
    '{status,select, 1 {Reward according to the point number of points} other {Unknown}}',
  'programme.description': 'Description',
  'programme.deadline.validator': 'The starting time is not coincided with the end time',
  'programme.quantity.validator':
    'The quantity of the discount must be greater than 0 and must be an integer',
  'programme.score.validator': 'The number of points must be greater than 0',
  'programme-info.router.name': 'Program information on the point number',

  'programme.item.name': 'Donation',
  'programme.femaleItem.name': 'Female items gifts',
  'programme.maleItem.name': 'Male items gift',
  'programme-info.status': 'Status',
  'programme-info.customer.nickName': 'Customer name',
  'programme-info.customer.phoneNumber': 'Phone number',
  'programme-info.createdAt': 'Receiving time',
  'programme-info.title': 'Program information',
  'programme-info.customer.title': 'List of Customers Receiving rewards',

  'programme.radio-all': 'Applicable for all gender',
  'programme.radio-detail': 'According to the specific gender',
  'programme.maleItemId': 'Male item',
  'programme.femaleItemId': 'Female item',
  'programme.femaleItemId.pl': 'Please choose female items',
  'programme.maleItemId.pl': 'Please choose male items',
  'programme.itemId.pl': 'Please choose the item',
  'programme.AccumulationTime': 'Valid accumulation time',
  
};
