export default {
  'rankings.router.name': 'Bảng xếp hạng',
  'rankings.title': 'Nhập',
  'weekly-ranking.list-name': 'Danh sách tháng chốt thưởng',
  'weekly-ranking.name-table': 'Bảng xếp hạng tháng',
  'ranking.tab.weekend': 'Bảng xếp hạng tháng',
  'ranking.tab.overall': 'Bảng xếp hạng Overall',
  'weekly-ranking.list.choose': 'Chọn đợt trúng thưởng muốn xem bảng xếp hạng',
  'weekly-ranking.list.weekend': 'Tháng',
  'weekly-ranking.list.status': 'Trạng thái quà',
  'weekly-ranking.list.dayTime': 'Thời gian chốt thưởng',
  'weeklyRanking.export': 'Xuất file',
  'weekly-ranking.list.isFinalize-false': 'Chưa chốt',
  'weekly-ranking.list.isFinalize-true': 'Đã phát',
  'weekly-ranking.list.isFinalize-null': 'Chưa phát',

  'weeklyRanking.awardName': 'Loại giải',
  'weeklyRanking.rank': 'Xếp hạng',
  'weeklyRanking.gift.name': 'Phần quà',
  'weeklyRanking.customer.nickName': 'Tên người dùng',
  'weeklyRanking.customer.phoneNumber': 'Số điện thoại',
  'weeklyRanking.coin': 'Số Green Coin xếp hạng',

  'overall-ranking.name': 'Bảng xếp hạng Overall',
  'overallRanking.rank': 'Xếp hạng',
  'overallRanking.customer.nickName': 'Tên người dùng',
  'overallRanking.customer.phoneNumber': 'Số điện thoại',
  'overallRanking.customer.address': 'Địa chỉ',

  'overallRanking.coin': 'Số Green Coin đã tiêu dùng',
  'overallRanking.remainingCoins': 'Số Green Coin hiện tại',
  'weekly.week': 'Tháng',
};
