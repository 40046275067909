export default {
  'users.title': 'Cài đặt người dùng',

  'users.tab.name': 'Tài khoản',
  'roles.tab.name': 'Vai trò',
  'users.name': 'Tên người dùng',
  'users.userName': 'Tên đăng nhập',
  'users.fullName': 'Tên người dùng',
  'users.phoneNumber': 'Số điện thoại',
  'users.action': 'Hành động',
  'users.update': 'Cập nhật người dùng',
  'users.information': 'Thông tin người dùng',
  'users.delete.confirm': 'Bạn có xác nhận xóa?',
  'users.password': 'Mật khẩu',
  'users.modal.form.password.placeholder': 'Vui lòng nhập mật khẩu',
  'users.userType': 'Vai trò',
  'users.status--1': 'Đang hoạt động',
  'users.status--2': 'Dừng hoạt động',
  'users.avatarPicture': 'Ảnh đại diện',
  'users.create': 'Thêm tài khoản',
  'users.delete': 'Xóa người dùng',
  'user.content.delete': 'Bạn có chắc chắn muốn xóa người dùng này không?',
  'users.citizenId': 'Số CMND/CCCD',
  'users.email': 'Email',
  'users.birthday': 'Ngày sinh',
  'users.address': 'Địa chỉ',
  'users.gender': 'Giới tính',
  'users.male': 'Nam',
  'users.female': 'Nữ',
  'users.other': 'Khác',
  'users.countryName': 'Tên quốc gia',
  'users.status': 'Trạng thái hoạt động',
  'users.updatedAt': 'Cập nhật lần cuối',
  'users.role':'Vai trò',
  'users.status.param':
    '{status,select, 1 {Đang hoạt động} 2 {Dừng hoạt động} other {Không xác định}}',
};
