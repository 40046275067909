export default {
  'programme.name.router': 'Chương trình vật phẩm',
  'programme.title': 'Quản lý chương trình vật phẩm',
  'programme.code': 'Mã chương trình',
  'programme.name': 'Tên chương trình',
  'programme.type': 'Loại chương trình',
  'programme.quantity': 'Số lượng ưu đãi',
  'programme.status': 'Trạng thái hoạt động',
  'programme.deadline': 'Thời hạn',
  'programme.usedQuantity': 'Số lượng sử dụng',

  'programme.status.params':
    '{status,select, 1 {Đang hoạt động} 2 {Chưa tới thời hạn} 3 {Dừng hoạt động} 4 {Kết thúc} other {Không xác định}}',
  'programme.type.scoreAchieve': 'Thưởng theo mốc số điểm',
  'programme.type.params': '{status,select, 1 {Thưởng theo mốc số điểm} other {Không xác định}}',
  'programme.InProgress': 'Đang hoạt động',
  'programme.UnProgress': 'Chưa tới thời hạn',
  'programme.Suspended': 'Dừng hoạt động',
  'programme.Conclude': 'Kết thúc',
  'programme.delete.title': 'Xóa chương trình',
  'programme.delete.content': 'Bạn có chắc chắn muốn xóa chương trình này không?',
  'programme.score': 'Điểm tích lũy',
  'programme.groupItem': 'Nhóm vật phẩm',
  'programme.itemId': 'Vật phẩm',
  'programme-add.name': 'Thêm chương trình thưởng theo mốc số điểm',
  'programme-edit.name': 'Cập nhật chương trình thưởng theo mốc số điểm',
  'programme.placeholder.quantity': 'Vui lòng nhập số lượng ưu đãi',
  'programme.placeholder.score': 'Vui lòng nhập Điểm tích lũy',
  'programme-info.router.name': 'Thông tin chương trình thưởng theo mốc số điểm',
  'programme.description': 'Mô tả',
  'programme.deadline.validator': 'Thời hạn bắt đầu không được trùng với thời hạn kết thúc',
  'programme.quantity.validator': 'Số lượng ưu đãi phải lớn hơn 0 và phải là số nguyên',
  'programme.score.validator': 'Điểm tích lũy phải lớn hơn 0',

  'programme-info.title': 'Thông tin chương trình',
  'programme-info.customer.title': 'Danh sách khách hàng nhận thưởng',

  'programme.item.name': 'Vật phẩm tặng',
  'programme.femaleItem.name': 'Vật phẩm nữ tặng',
  'programme.maleItem.name': 'Vật phẩm nam tặng',
  'programme-info.status': 'Trạng thái',
  'programme-info.customer.nickName': 'Tên khách hàng',
  'programme-info.customer.phoneNumber': 'Số điện thoại',
  'programme-info.createdAt': 'Thời gian nhận thưởng',

  'programme.radio-all': 'Áp dụng cho mọi  giới tính',
  'programme.radio-detail': 'Theo giới tính cụ thể',
  'programme.maleItemId': 'Vật phẩm nam',
  'programme.femaleItemId': 'Vật phẩm nữ',
  'programme.femaleItemId.pl': 'Vui lòng chọn vật phẩm nữ',
  'programme.maleItemId.pl': 'Vui lòng chọn vật phẩm nam',
  'programme.itemId.pl': 'Vui lòng chọn vật phẩm',
  'programme.AccumulationTime': 'Thời gian tích lũy hợp lệ',
};
