export default {
  'rankings.router.name': 'Ranking',
  'weekly-ranking.list-name': 'List of prizes closing months',
  'weekly-ranking.name-table': 'Monthly rankings',
  'ranking.tab.weekend': 'Monthly rankings',
  'ranking.tab.overall': 'Overall ranking',
  'weekly-ranking.list.choose': 'Select the winning wins you want to see the rankings',
  'weekly-ranking.list.weekend': 'Month',
  'weekly-ranking.list.status': 'Gift status',
  'weekly-ranking.list.dayTime': 'The deadline for claiming a prize',
  'weeklyRanking.export': 'Export file',
  'weekly-ranking.list.isFinalize-false': 'Not yet',
  'weekly-ranking.list.isFinalize-true': 'Has been distributed',
  'weekly-ranking.list.isFinalize-null': 'Not distributed',

  'weeklyRanking.awardName': 'Prize',
  'weeklyRanking.rank': 'Rank',
  'weeklyRanking.gift.name': 'Gift',
  'weeklyRanking.customer.nickName': 'User name',
  'weeklyRanking.customer.phoneNumber': 'Phone number',
  'weeklyRanking.coin': 'Number of Green Coin ranked',

  'overall-ranking.name': 'Overall ranking',
  'overallRanking.rank': 'Rank',
  'overallRanking.customer.nickName': 'User name',
  'overallRanking.customer.phoneNumber': 'Phone number',
  'overallRanking.customer.address': 'Address',

  'overallRanking.coin': 'Number of consumed green coins',
  'overallRanking.remainingCoins': 'The current Green Coin number',
  'weekly.week': 'Month',
};
