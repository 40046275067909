import React from 'react';

import { IRouter } from '@routers/interface';

export const routerDeviceInfo: IRouter = {
  path: '/device/info/:id',
  loader: React.lazy(() => import('./index')),
  exact: true,
  masterLayout: true,
  name: 'device-info.name', //translate here for breadcrumb and sidebar
};
