import React from 'react';

import { IRouter } from '@routers/interface';
import { PermissionEnum } from '@modules/permissions/entity';

export const routerSortRankingGift: IRouter = {
  path: '/gift/sort-ranking-gift',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'sortRankingGift.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  permissionCode: PermissionEnum.RankingGiftsUpdate,
};
