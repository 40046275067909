export default {
  'customer.router.name': 'Khách hàng',
  'customer.title': 'Quản lý khách hàng',
  'customer.update': 'Cập nhật khách hàng',
  'customer.name': 'Họ và tên',
  'customer.nickName': 'Tên người dùng',
  'customer.customerName': 'Tên khách hàng',
  'customer.phoneNumber': 'Số điện thoại',
  'customer.fullName': 'Họ và tên',
  'customer.address': 'Địa chỉ',
  'customer.province': 'Tỉnh/Thành phố',
  'customer.district': 'Quận/Huyện',
  'customer.ward': 'Phường/Xã',
  'customer.status': 'Trạng thái hoạt động',
  'customer.info': 'Thông tin khách hàng',

  'ItemWarehouse.tab.name': 'Kho vật phẩm',
  'VouchersWarehouse.tab.name': 'Kho voucher  quy đổi',
  'GiftWarehouse.tab.name': 'Kho quà',
  'ScoreHistory.tab.name': 'Lịch sử tích điểm',
  'RankHistory.tab.name': 'Lịch sử xếp hạng',

  'customer.validate-phone': 'Số điện thoại không hợp lệ',

  'customer.greenCoin': 'Green Coin </br> (Còn lại/tổng tích lũy)',
  'customer.greenCoin.xlsx': 'Green Coin (Còn lại/tổng tích lũy)',
  'customer.greenCoin.used': 'Green Coin đã sử dụng',
  'customer.lastWeekRanking': 'Xếp hạng tháng trước',
  'customer.specificAddress': 'Địa chỉ',
  'customer.currentWeekRanking': 'Xếp hạng tháng hiện tại',
  'customer.overallRanking': 'Xếp hạng Overall',
  'customer.action': 'Hành động',
  'customer.status.param':
    '{status,select, 1 {Đang hoạt động} 2 {Dừng hoạt động} other {Không xác định}}',
  'customer.accumulatedCoins': 'Số coin tích lũy',
  'customer.remainingCoins': 'Số coin sử dụng',

  'scoreHistory.device.specificAddress': 'Địa chỉ lắp đặt',
  'scoreHistory.device.name': 'Thiết bị',
  'scoreHistory.time': 'Thời gian',
  'scoreHistory.invalidQuantity': 'Số lượng chai brand khác',
  'scoreHistory.validQuantity': 'Số lượng chai là Aqua',
  'scoreHistory.score': 'Số điểm đạt được',
  'scoreHistory.card.score': 'Tổng số điểm đạt được',
  'scoreHistory.card.invalidQuantity': 'Tổng số chai brand khác',
  'scoreHistory.card.validQuantity': 'Tổng số chai Aqua',

  'giftWarehouse.status.type': '{status,select, 1 {Hiện vật} 2 {Voucher} other {Không xác định}}',
  'giftWarehouse.status.source':
    '{status,select, 1 {Admin trao tặng} 2 {Bảng xếp hạng tháng} other {Không xác định}}',
  'giftWarehouse.total-gift': 'Tổng số quà',
  'giftWarehouse.rank-gift': 'Quà xếp hạng',
  'giftWarehouse.different-gift': 'Quà khác',

  'giftWarehouse.awardName': 'Loại giải',
  'giftWarehouse.rank': 'Xếp hạng',
  'giftWarehouse.code': 'Mã quà tặng',
  'giftWarehouse.name': 'Tên quà tặng',
  'giftWarehouse.type': 'Loại quà tặng',
  'giftWarehouse.coin': 'Số Green Coin',
  'giftWarehouse.sourceGift': 'Nguồn quà tặng',
  'giftWarehouse.createdAt': 'Thời gian',
  'giftWarehouse.giftRevokingStatus': 'Trạng thái quà tặng',
  'giftWarehouse.status.giftRevokingStatus':
    '{status,select, 1 {Đã thu hồi} 2 {Đã phát} other {Unknown}}',
  'giftWarehouse.action': 'Hành động',
  'giftWarehouse.type-artifact': 'Hiện vật',
  'giftWarehouse.type-voucher': 'Voucher',
  'giftWarehouse.source-awardSource': 'Admin trao tặng',
  'giftWarehouse.source-rankSource': 'Bảng xếp hạng tháng',
  'giftWarehouse.source-Item-offers': 'Ưu đãi vật phẩm',
  'giftWarehouse.information': 'Thông tin quà tặng',

  'customers.createdAt.time': 'Thời gian tạo',

  'itemWarehouse.action': 'Hành động',
  'itemWarehouse.item.thumbnail': 'Hình ảnh',
  'itemWarehouse.item.code': 'Mã vật phẩm',
  'itemWarehouse.item.name': 'Tên vật phẩm',
  'itemWarehouse.item.group.name': 'Nhóm vật phẩm',
  'itemWarehouse.item.quantity': 'Green Coin',
  'itemWarehouse.item.collection.name': 'Bộ sưu tập',
  'itemWarehouse.item.type': 'Loại vật phẩm',
  'itemWarehouse.equippingStatus': 'Trạng thái',
  'itemWarehouse.createdAt': 'Thời gian mua',
  'itemWarehouse.status.type':
    '{status,select, 1 {Loại bình thường} 2 {Loại bí ẩn} other {Không xác định}}',
  'itemWarehouse.status.equippingStatus':
    '{status,select, 1 {Đang trang bị} 2 {Không trang bị} 3 {Đã thu hồi} other {Không xác định}}',
  'itemWarehouse.status.normal': 'Loại bình thường',
  'itemWarehouse.status.mystery': 'Loại bí ẩn',
  'itemWarehouse.status.equipping': 'Đang trang bị',
  'itemWarehouse.status.notEquipping': 'Không trang bị',
  'itemWarehouse.status.revoked': 'Đã thu hồi',
  'itemWarehouse.card.itemQuantity': 'Tổng số vật phẩm',
  'itemWarehouse.card.coinQuantity': 'Tổng số green coin đã mua vật phẩm',
  'itemWarehouse.information': 'Thông tin vật phẩm',
  'customer.title.recall': 'Thu hồi vật phẩm',
  'customer.content.recall': 'Bạn có chắc chắn muốn thu hồi vật phẩm này không?',
  'customer.title.gift-recall': 'Thu hồi quà tặng',
  'customer.content.gift-recall': 'Bạn có chắc chắn muốn thu hồi quà tặng này không?',

  'vouchersWarehouse.price': 'Điểm quy đổi (Green Coin)',
  'vouchersWarehouse.name': 'Loại voucher',
  'vouchersWarehouse.receivingTime': 'Thời gian nhận',
  'vouchersWarehouse.usageStatus': 'Trạng thái sử dụng',
  'vouchersWarehouse.totalVoucher': 'Tổng số voucher',
  'vouchersWarehouse.totalGreencoin': 'Tổng số điểm quy đổi',

  'vouchersWarehouse.used': 'Đã sử dụng',
  'vouchersWarehouse.unUsed': 'Chưa sử dụng',
  'vouchersWarehouse.normal': 'Loại thường',
  'vouchersWarehouse.prioritize': 'Loại ưu tiên',
  'vouchersWarehouse.information': 'Thông tin kho voucher',
  'Green.Coin': 'Green Coin',
  'vouchersWarehouse.amountCoin': 'Số tiền/điểm nhận được',
  'vouchersWarehouse.phoneNumber': 'Số điện thoại nhận',

  'rankHistory.numberWeeksRanked': 'Tổng số tháng đã xếp hạng',
  'rankHistory.awardWinningRatings': 'Số xếp hạng đạt giải',
  'rankHistory.title.closingTime': 'Thời gian',
  'rankHistory.rank': ' Xếp hạng',
  'rankHistory.awardName': 'Loại giải',
  'rankHistory.coin': 'Green Coin xếp hạng',
  'rankHistory.week.id': 'Số tháng',
  'rankHistory.closingTime': 'Thời gian chốt thưởng',
  'customer.avatarPicture': 'AVT',
  'customer.userName': 'Tên đăng nhập',
  'customer.createdAt': 'Ngày tạo',
  'customer.currentWeekRanking.notnull': 'Có tích điểm tháng này',
  'customer.currentWeekRanking.null': 'Chưa tích điểm tháng này',
  'customer.currentWeekRanking.action': 'Hoạt động tháng này',

  'customer.usedCoins': 'Số coin đã dùng',
};
