import React from 'react';

import { IRouter } from '@routers/interface';
import { PermissionEnum } from '@modules/permissions/entity';

export const routerGiftGiving: IRouter = {
  path: '/gift-giving/:id',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'giftGiving.title', //translate here for breadcrumb and sidebar
  masterLayout: true,
  permissionCode: PermissionEnum.OtherGiftsDistribute,
};
