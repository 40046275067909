import React from 'react';

import { TableOutlined } from '@ant-design/icons';
import { IRouter } from '@routers/interface';
import { PermissionEnum } from '@modules/permissions/entity';

export const routerDeviceGroups: IRouter = {
  path: '/device-groups',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'deviceGroups.name', //translate here for breadcrumb and sidebar
  icon: <TableOutlined />,
  permissionCode: PermissionEnum.DeviceGroupsView,
};
