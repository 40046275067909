export default {
  //first page
  'item.title': 'Quản lý vật phẩm trong cửa hàng',
  'item.name': 'Quản lý vật phẩm',

  'item.filter.group': 'Nhóm vật phẩm',
  'item.filter.collection': 'Bộ sưu tập',
  'item.filter.bussiness-status': 'Trạng thái kinh doanh',
  'item.filter.start': 'Thời gian bắt đầu',
  'item.filter.end': 'Thời gian kết thúc',
  'item.filter.duration': 'Thời hạn',

  'item.table.img': 'Hình ảnh',
  'item.table.id': 'Mã vật phẩm',
  'item.table.name': 'Tên vật phẩm',
  'item.table.category': 'Nhóm vật phẩm',
  'item.table.green-coin': 'Giá giảm / Giá gốc',
  'item.table.quantity': 'Số lượng đã bán / tổng',
  'item.table.collection': 'Bộ sưu tập',
  'item.table.duration': 'Thời hạn',
  'item.table.bussiness-status': 'Trạng thái kinh doanh',
  'item.table.bussiness-status.param':
    '{status,select, 1 {Đang kinh doanh} 2 {Chưa kinh doanh} 3 {Đã kết thúc} 4{Ngưng kinh doanh} other {Không xác định}}',
  'item.table.promotion-number': 'Số lượng ưu đãi',
  'item.table.bussiness-status.1': 'Đang kinh doanh',
  'item.table.bussiness-status.2': 'Chưa kinh doanh',
  'item.table.bussiness-status.3': 'Đã kết thúc',
  'item.table.bussiness-status.4': 'Ngưng kinh doanh',

  'item.button.updateStatus': 'Chuyển trạng thái kinh doanh',
  'item.confirm.conclude': 'Bạn có xác nhận dừng kinh doanh?',
  'item.confirm.available': 'Bạn có xác nhận chuyển sang kinh doanh?',
  'item.confirm.delete': 'Bạn có xác nhận xóa vật phẩm này?',

  //add/edit page
  'item.add': 'Thêm vật phẩm',
  'item.update': 'Cập nhật vật phẩm',

  'item.add.id': 'Mã vật phẩm',
  'item.add.id.placeholder': 'Vui lòng nhập mã vật phẩm',
  'item.add.name': 'Tên vật phẩm',
  'item.add.name.placeholder': 'Vui lòng nhập tên vật phẩm',
  'item.add.layer': 'Layer',
  'item.add.left': 'Tay trái',
  'item.add.right': 'Tay phải',
  'item.add.both': 'Hai tay',
  'item.add.quantity': 'Số lượng',
  'item.add.price': 'Giá vật phẩm',
  'item.add.price.placeholder': 'Vui lòng nhập Giá vật phẩm',
  'item.add.thumbnail': 'Thumbnail',
  'item.add.thumbnail.placeholder': 'Vui lòng nhập Thumbnail',
  'item.add.overlay': 'Overlay',
  'item.add.overlay.placeholder': 'Vui lòng nhập Overlay',
  'item.add.type': 'Loại ưu đãi',
  'item.add.itemType': 'Loại vật phẩm',
  'item.add.type--1': 'Thường',
  'item.add.type--2': 'Bí ẩn',
  'item.add.type.placeholder': 'Vui lòng chọn loại ưu đãi',
  'item.add.category': 'Nhóm vật phẩm',
  'item.add.category.placeholder': 'Vui lòng chọn nhóm vật phẩm',
  'item.add.collection': 'Bộ sưu tập',
  'item.add.collection.placeholder': 'Vui lòng chọn bộ sưu tập',
  'item.add.collection.add': 'Thêm bộ sưu tập',
  'item.add.duration': 'Thời hạn',
  'item.add.gender': 'Giới tính',
  'item.add.gender--Male': 'Nam',
  'item.add.gender--Female': 'Nữ',
  'item.add.gender--Unisex': 'Mọi giới tính',
  'item.add.sort-type': 'Loại sắp xếp:',
  'item.add.sort-type.placeholder': 'Vui lòng nhập Loại sắp xếp:',
  'item.add.sort-type--1': 'Mặc định',
  'item.add.sort-type--2': 'Ưu tiên',
  'item.add.bussiness-status': 'Trạng thái kinh doanh',
  'item.add.bussiness-status--available': 'Đang kinh doanh',
  'item.add.bussiness-status--suspended': 'Ngưng kinh doanh',
  'item.add.bussiness-status.placeholder': 'Vui lòng nhập Trạng thái kinh doanh',

  //info page
  'item.info.title': 'Thông tin vật phẩm',

  'item.info.id': 'Mã vật phẩm',
  'item.info.name': 'Tên vật phẩm',
  'item.info.quantity': 'Số lượng',
  'item.info.price': 'Giá vật phẩm',
  'item.info.thumbnail': 'Thumbnail',
  'item.info.overlay': 'Overlay',
  'item.info.type': 'Loại vật phẩm',
  'item.info.type--2': 'Voucher',
  'item.info.type--1': 'Hiện vật',
  'item.info.category': 'Nhóm vật phẩm',
  'item.info.collection': 'Bộ sưu tập',
  'item.info.sort-type': 'Loại sắp xếp',
  'item.info.sort-type--1': 'Mặc định',
  'item.info.sort-type--2': 'Ưu tiên',
  'item.info.bussiness-status': 'Trạng thái kinh doanh',
  'item.info.date-start': 'Thời gian bắt đầu',
  'item.info.date-end': 'Thời gian kết thúc',
  'item.info.sold': 'Số lượng đã bán',
  'item.info.preferentialStatus.param':
    '{status , select, 1 {Chưa diễn ra} 2 {Đang diễn ra} 3 {Đã kết thúc} other {Không xác định}}',

  //history page
  'item.history.title': 'Lịch sử mua vật phẩm',

  'item.history.user-name': 'Tên người dùng',
  'item.history.user-phone': 'Số điện thoại',
  'item.history.purchase-time': 'Thời gian mua',
  'item.history.price-type': 'Loại giá mua',
  'item.history.price-type.param':
    '{status , select, 1 {Giá gốc} 2 {Giảm giá} other {Không xác định}}',
  'item.history.price': 'Giá mua(Green Coin)',

  'item.add.voucherType': 'Loại ưu đãi',
  'item.add.placeholder.voucherType': 'Vui lòng chọn loại ưu đãi',
  'item.add.voucherType-discount': 'Giảm giá',
  'item.add.voucherType-itemGift': 'Tặng vật phẩm',
  'item.add.voucherType-gift': 'Quà tặng',
  'item.add.quantityDiscount.placeholder': 'Vui lòng nhập số lượng',
  'item.add.placeholder.percent': 'Vui lòng nhập số phần trăm giảm',
  'item.add.placeholder.groupItem': 'Vui lòng chọn nhóm vật phẩm',
  'item.add.placeholder.item': 'Vui lòng chọn vật phẩm',
  'item.add.placeholder.giftType': 'Vui lòng chọn loại quà tặng',
  'item.add.placeholder.gift': 'Vui lòng chọn quà tặng',
  'btn-add-discount': 'Thêm ưu đãi',
  'item.add.info-title': '1. Thông tin',
  'item.add.discount-title': '2. Ưu đãi',
  'item.add-form.type': 'Loại ưu đãi',
  'item.add.placeholder.type': 'Vui lòng chọn loại ưu đãi',
  'item.add-form.quantity': 'Số lượng',
  'item.add-form.time': 'Thời hạn',
  'item.add-form.discountPercent': 'Tỉ lệ giảm (%)',
  'item.add-form.priceDiscount': 'Giá sau giảm',
  'item.add.preferentialItemId': 'Vật phẩm',
  'item.add.giftType': 'Loại quà tặng',
  'item.add.preferentialGiftId': 'Quà tặng',
  'item.add.placeholder.Artifact': 'Hiện vật',
  'item.add.placeholder.Voucher': 'Voucher',
};
